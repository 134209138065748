import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useEffect } from 'react'
import Area from './component/tabs/Area.tab.component';
import Linea from './component/tabs/Linea.tab.component';
import MethoDetection from './component/tabs/MethoDetection.tab.component';
import Planta from './component/tabs/Planta.tab.component';
import PuntoFuga from './component/tabs/PuntoFuga.tab.component';
import Medidor from './component/tabs/Medidor.tab.component';


function DashBoarSu({metadata}) {
  
  const [value, setValue] = React.useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className='container fondo-div my-3 p-3'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='t-shadow'>Items</h3>      
          </div>
          <div className='col-12'>
            
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons="auto"
                >
                  {
                    metadata.tuser==='megaUser'?<Tab label="Planta" value="1" />:null
                  }
                  <Tab label="Area" value="2" />
                  <Tab label="Linea" value="3" />
                  <Tab label="Punto Fuga" value="4" />
                  <Tab label="Metodo Deteccion" value="5" />
                  {/* <Tab label="Crear Medidor" value="6" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Planta metadata={metadata} />
              </TabPanel>
              <TabPanel value="2">
               <Area metadata={metadata} />                
              </TabPanel>
              <TabPanel value="3">
                <Linea metadata={metadata} />
              </TabPanel>
              <TabPanel value="4">
                <PuntoFuga metadata={metadata} />
              </TabPanel>
              <TabPanel value="5">
                <MethoDetection metadata={metadata} />
              </TabPanel>
              <TabPanel value="6">
                <Medidor metadata={metadata} />
              </TabPanel>
            </TabContext>
          </Box>

          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoarSu
