import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { url } from '../../../varEntorn/Url.link'

export default function PuntoFuga({metadata, HandleSetValoresSelect, valores}) {
  const [ArrData, setArrData] = useState([])

  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getpuntofugaselect',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        empresa: metadata.empresa,
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      setArrData(respons.data.body)
      // console.log(respons.data.body)
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    fetchulta()
  }, [])

  return (
    <>
      <TextField
        id="outlined-select-currency"
        select
        label="Punto Fuga"
        name="punto_fuga"
        value={valores.punto_fuga}
        onChange={HandleSetValoresSelect}
        size='small'
        fullWidth
      >
        {
          ArrData.map((itm, index)=>
            <MenuItem key={index} value={itm.NOMBRE}>{itm.NOMBRE}</MenuItem>
          )
        }
      </TextField>
    </>
  )
}
