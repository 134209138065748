import React from 'react'

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton } from '@mui/material';
import { url } from '../../../../../varEntorn/Url.link';

export default function Delete({metadata, setIsReload, campo, data}) {
  
  const HandleDeleteItem = async () => {
    let fisure = window.confirm('Esta seguro de Eliminar el Item')
    if(fisure){
      /**standar de consulta a endpont con fetch */
      const listaCuenta = await fetch(url+'/fugatorio/eliminaitemad',{
        method: 'DELETE',
        headers: {
          'authorization': metadata.token,
          'aplicacion': 'FUGAMONIACO', 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: data,
          campo: campo,
        })
      })
      let respons = await listaCuenta.json()

      /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
       * del session storage y se redirige al inicio
       */
      if(!respons.data.success){
        sessionStorage.clear()
        window.location.href = '/'
      }else{
        //window.location.reload()
        //setDataList(respons.data.body)
      
        setIsReload(true)
      }
      /**standar de consulta a endpont con fetch */
      /** a continuacion ingrese la logica para una respons success por parte del endpoint */
    }else{
      return
    }
    
  }

  return (
    <>
      <IconButton aria-label="delete" color='error' size='small' onClick={()=>HandleDeleteItem()}>
        <DeleteForeverOutlinedIcon />
      </IconButton>
    </>
  )
}
