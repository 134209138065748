import React from 'react'

function GraficarSu() {
  return (
    <>
      <div className='container fondo-div my-3 p-3'>
        <div className='row'>
          <div className='col'>
            <h1>Graficos</h1>
          </div>
        </div>
      </div>
      <div className='container fondo-div my-3 p-3 text-center'>
        <div className='row'>
          <div className='col-12'>
            seleccion planta para ver datos
          </div>
          <div className='col-12'>
            Graficos
          </div>
        </div>
      </div>
    </>
  )
}

export default GraficarSu
