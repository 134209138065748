import React from 'react'

export default function Index({metadata}) {
  return (
    <div className='p-5 text-white'>
      <h1 className='t-shadow'>Bienvenido {metadata.user} </h1>
      {/* <a href='./sessionclose'>Salir</a> */}
    </div>
  )
}
