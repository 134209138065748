import { url } from "../../../../varEntorn/Url.link";

export const GetDataChart = ({metadata, selectEmp}) => {

  let empresa_ = ''
  if(metadata.tuser === 'megaUser' && selectEmp.length === 0)empresa_ = null
  else if(metadata.tuser === 'megaUser' && selectEmp.length !== 0)empresa_ = selectEmp
  else empresa_ = [metadata.empresa]

  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/fugatorio/getdatatochart',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          empresa: empresa_
        })
      })

        const reqkkst = await query.json();
        // console.log(reqkkst)
        if(reqkkst.success){
          resolve(reqkkst.body)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    

  })
}
