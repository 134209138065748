import React, { useEffect, useState } from 'react'

export const PaginPages = ({paginacion,getOc}) => {
  let {totalPages,page} = paginacion
  const [NPaginas, setNPaginas] = useState([])
  // console.log(paginacion)
  useEffect(() => {
    let allpages = []
    for (let pgn = 1; pgn <= totalPages; pgn++) {
      allpages.push(pgn)
    }
    setNPaginas(allpages)
  }, [paginacion])
  
  return (
    <>
    {
      NPaginas.map(pg=>(
        <li className={pg===page?"page-item active":"page cursor"} onClick={pg===page?null:()=>getOc(pg)} key={pg}><a className="page-link">{pg}</a></li>
      ))
    }
    </>
  )
}
