import React, { useEffect, useState } from 'react'

import { url } from '../../../../varEntorn/Url.link'

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import Edit from './component/Edit.component';
import Add from './component/Add.component';
import Delete from './component/Delete.component';

export default function MethoDetection({metadata}) {
  // console.log(metadata.empresa)
  const [DataList, setDataList] = useState([])
  const [IsReload, setIsReload] = useState(false)

  const HandleSendDatoToServer = async () => {
    setDataList([])
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getmedesu',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO', 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        planta: metadata.empresa,
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      // console.log(respons.data.body)
      setDataList(respons.data.body)
      setIsReload(false)
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    HandleSendDatoToServer()
  }, [IsReload])
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 pb-3'>
          <Add metadata={metadata} setIsReload={setIsReload} campo={'METDETEC'} />
        </div>
        <div className='col-12 table-responsive-sm'>
          <table className="table table-hover table-sm">
            <thead className='bg-dark text-white'>
              <tr>
                <th scope="col">#</th>
                {/* <th scope="col">Planta</th> */}
                <th scope="col" className=''>Metodo de Deteccion</th>
                <th scope="col" className='text-center'>Estado</th>
                <th scope="col" className='text-center'>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                DataList.map((plt, index)=>
                  <tr key={index} className='trn'>
                    <td scope="row" className='trn'>{index+1}</td>
                    {/* <td className=' trn'>{plt.PLANTA}</td> */}
                    <td className=' trn'>{plt.NOMBRE}</td>
                    <td className='text-center trn pt-2'>
                      {
                        plt.STATE?<CheckCircleOutlinedIcon color='success' />
                        :<DoNotDisturbAltOutlinedIcon color='error' />
                      }
                    </td>
                    <td className='text-center trn'>
                      <Edit metadata={metadata} setIsReload={setIsReload} campo={'METDETEC'} data={plt} />
                      <Delete metadata={metadata} setIsReload={setIsReload} campo={'METDETEC'} data={plt._id}/>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        <div className='col-12'></div>
      </div>
    </div>
  )
}
