import { Drawer, IconButton, MenuItem, TextField } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import React, { useState } from 'react'
import AreaSelect from '../../user/component/Area.select.component'
import LineaSelect from '../../user/component/Linea.select.component'
import PuntoFugaSelect from '../../user/component/PuntoFuga.select.component'
import MetDetecSelect from '../../user/component/MethDetec.select.component'
import { url } from '../../../varEntorn/Url.link';

export default function EditData({metadata,valores, setIsReload}) {
  //console.log(valores)
  const [state, setState] = useState({bottom: false});
  const [ValuesData, setValuesData] = useState({
    'TIPO': valores.TIPO,
    'FECHA_EVENTO': valores.FECHA_EVENTO.split(' ').join('T'),
    'DURACION': valores.DURACION,
    'CONCENTRACION': valores.CONCENTRACION,
    'FECHA_CIERRE': valores.FECHA_CIERRE.split(' ').join('T'),
    'OPERADOR': valores.OPERADOR,
    'COORDENADA': valores.COORDENADA,

    'AREA': valores.AREA,
    'LINEA': valores.LINEA,
    'PUNTO_FUGA': valores.PUNTO_FUGA,
    'METODO_DETECCION': valores.METODO_DETECCION,

    'EMPRESA': metadata.empresa,
    'ID': valores.ID
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  const HandleSetValoresSelect = async (e) => {
    // console.log(e.target.name, e.target.value)
    setValuesData({
      ...ValuesData,[e.target.name]: e.target.value
    })
  }

  const HandleSendDataEditToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/editdataeventad',{
     method: 'POST',
     headers: {
       'authorization': metadata.token,
       'aplicacion': 'FUGAMONIACO',
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       data: ValuesData
     })
   })
   let respons = await listaCuenta.json()

   /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
    * del session storage y se redirige al inicio
    */
   if(!respons.data.success){
     sessionStorage.clear()
     window.location.href = '/'
   }else{
    //  console.log(respons.data)
     // setUsuarios(respons.data.body)
     setIsReload(true)
     setState({bottom: false})
     // window.location.reload()
   }
   /**standar de consulta a endpont con fetch */
   /** a continuacion ingrese la logica para una respons success por parte del endpoint */
 }

  return (
    <>
    <IconButton aria-label="edit" className='bg-info text-white' onClick={toggleDrawer('bottom', true)}>
      <BorderColorOutlinedIcon />
    </IconButton>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        <div className="container table-responsive py-2">
          <div className="row">
            <div className="col-12"><h5>Editar Datos</h5></div>
            <div className='col-12 col-lg-6'>
              <div className='row'>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    id="outlined-basic"
                    name="TIPO"
                    label="Evento"
                    size="small"
                    variant="outlined"
                    value={ValuesData.TIPO}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                  />
                </div>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    type='datetime-local'
                    id="outlined-basic"
                    name="FECHA_EVENTO"
                    label="Fecha Evento"
                    size="small"
                    variant="outlined"
                    value={ValuesData.FECHA_EVENTO}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                  />
                </div>
                <div className="col-12 py-1">
                  <AreaSelect metadata={metadata} HandleSetValoresSelect={HandleSetValoresSelect} valores={{area: valores.AREA}} />
                </div>
                <div className="col-12 py-1">
                  <LineaSelect metadata={metadata} HandleSetValoresSelect={HandleSetValoresSelect} valores={{linea: valores.LINEA}} />
                </div>
                <div className="col-12 py-1">
                  <PuntoFugaSelect metadata={metadata} HandleSetValoresSelect={HandleSetValoresSelect} valores={{punto_fuga: valores.PUNTO_FUGA}} />
                </div>
                <div className="col-12 py-1">
                  <MetDetecSelect metadata={metadata} HandleSetValoresSelect={HandleSetValoresSelect} valores={{deteccion: valores.METODO_DETECCION}} />
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='row'>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    type={'number'}
                    id="outlined-basic"
                    name="DURACION"
                    label="Duracion (minutos)"
                    size="small"
                    variant="outlined"
                    value={ValuesData.DURACION}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                    disabled
                  />
                </div>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    type={'number'}
                    id="outlined-basic"
                    name="CONCENTRACION"
                    label="Concentracion (ppm)"
                    size="small"
                    variant="outlined"
                    value={ValuesData.CONCENTRACION}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                  />
                </div>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    type='datetime-local'
                    id="id_fecha_cierre"
                    name="FECHA_CIERRE"
                    label="Fecha Cierre"
                    size="small"
                    variant="outlined"
                    
                    min='2022-01-15T20:20'
                    value={ValuesData.FECHA_CIERRE}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                  />
                </div>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    id="outlined-basic"
                    name="OPERADOR"
                    label="Tecnico"
                    size="small"
                    variant="outlined"
                    value={ValuesData.OPERADOR}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                    disabled
                  />
                </div>
                <div className="col-12 py-1">
                  <TextField fullWidth
                    id="outlined-basic"
                    name="COORDENADA"
                    label="Coordenada Plano"
                    size="small"
                    variant="outlined"
                    value={ValuesData.COORDENADA}
                    onChange={(e)=>HandleSetValoresSelect(e)}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12 py-2"> 
              <button className="btn btn-info float-right" onClick={()=>HandleSendDataEditToServer()}>
                Editar!!
                {/* {
                  isLoading?"Cargando":"Editar"
                } */}
                </button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}
