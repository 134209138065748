import React, { useEffect, useState } from 'react'

import AddCta from './component/AddCta.component';
import EditCta from './component/EditCta.component';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import { IconButton } from '@mui/material';
import { url } from '../../varEntorn/Url.link';

function DashBoarCuentas({metadata}) {
  //console.log(metadata)
  const [usuarios, setUsuarios] = useState([])
  const [Reload, setReload] = useState(false)
  
  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getusersadmin',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: metadata
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      // console.log(respons.data)
      setUsuarios(respons.data.body)
      setReload(false)
      // window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    fetchulta()
  }, [Reload])

  const HandleDeleteUser = async(id, nombre) => {
    let sure = window.confirm(`Esta seguro de eliminar esta cuenta a nombre de ${nombre}`)
    if(sure){
    // console.log(id)
        /**standar de consulta a endpont con fetch */
        const listaCuenta = await fetch(url+'/fugatorio/deleteusersu',{
          method: 'DELETE',
          headers: {
            'authorization': metadata.token,
            'aplicacion': 'FUGAMONIACO',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
          })
        })
        let respons = await listaCuenta.json()

        /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
         * del session storage y se redirige al inicio
         */
        if(!respons.data.success){
          sessionStorage.clear()
          window.location.href = '/'
        }else{
          // console.log(respons.data)
          fetchulta()
          //setUsuarios(respons.data.body)
          // window.location.reload()
        }
        /**standar de consulta a endpont con fetch */
        /** a continuacion ingrese la logica para una respons success por parte del endpoint */
    }else{
      return
    }
  }

  return (
   <>
    <div className='container fondo-div my-3 p-3'>
      <div className='row'>
        <div className='col'>
          <h3 className='t-shadow'>Cuentas</h3>
        </div>
      </div>
    </div>
    <div className='container fondo-div my-3 p-3 text-center'>
      <div className='row'>
        <div className='col-12 my-2'>
          <AddCta setReload={setReload} metadata={metadata} />
        </div>
        <div className='col-12 table-responsive'>
        <table className="table table-hover table-sm">
          <thead className='bg-dark text-white'>
            <tr>
              <th scope="col">Planta</th>
              <th scope="col">Rut</th>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
              <th scope="col">Perfil</th>
              <th scope="col">Estado</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              usuarios.map((itm, index)=>
              // console.log(itm)
              <tr key={index}>
                <th scope="row"><small>{itm.empresa}</small></th>
                <td><small>{itm.rut}</small></td>
                <td><small>{itm.nombre}</small></td>
                <td><small>{itm.user}</small></td>
                <td><small>{itm.rol}</small></td>
                <td>
                  {
                    itm.state?<AccountCircleOutlinedIcon color='success' />
                    :<NoAccountsOutlinedIcon color='error' />
                  }
                  </td>
                <td>
                  <EditCta setReload={setReload} metadata={metadata} usuario={itm} />
                  {
                    metadata.rut===itm.rut?
                    <IconButton size='small' color='error' aria-label="delete" disabled onClick={()=>HandleDeleteUser(itm._id, itm.nombre)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                    :<IconButton size='small' color='error' aria-label="delete" onClick={()=>HandleDeleteUser(itm._id, itm.nombre)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  }
                </td>
              </tr>
              )
            }
          </tbody>
        </table>
        </div>
      </div>
    </div>
   </>
  )
}

export default DashBoarCuentas
