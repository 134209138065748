import { Button, Drawer, TextField } from '@mui/material'
import React, { useState } from 'react'

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { url } from '../../../varEntorn/Url.link';
import GetDataRut from '../../extApi/GetRut.api';

export default function AddCta({metadata, setReload}) {
  const [state, setState] = useState({bottom: false})
  const [addCtaData, setaddCtaData] = useState({
    'nombre': '',
    'correo':'',
    'rut': '',
    'empresa': metadata.empresa,
    'rol': 'user',
    'state': true,
    'aplicacion': 'FUGAMONIACO'
  })
  const [isDeseable, setisDeseable] = useState(false)
  const [isOkForm, setisOkForm] = useState(true)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleSetDataRut = async (e) => {
    let rut_ = e.target.value
    var clean = rut_.replace(/-/g,"")
    var largo = clean.length
    var prin = clean.substr(0,(largo-1));
    var nal = clean.substr((largo-1),largo)
    rut_ = prin+"-"+nal
    //console.log(rut_)
    setaddCtaData({...addCtaData,['rut']:rut_})
    // console.log(rut_.length)
    if(rut_.length >=10){
      let metarut = await GetDataRut(rut_)
      if(metarut.success){
        setisDeseable(true)
        setaddCtaData({
          ...addCtaData, ['nombre']: metarut.data.nom_corto, ['rut']: metarut.data.rut
        })
      }else{
        setisDeseable(false)
        setaddCtaData({
          ...addCtaData,['nombre']: '', ['rut']: metarut.data.rut
        })
      }
      return
      
    }
  }

  const HandleSetAddCtaData = async (e) => {
    isPssed = true
    let list = Object.keys(addCtaData)
    list.forEach(elmt => {
      if(addCtaData[elmt] === ''){
        isPssed = false
      }
    });
    if(isPssed)setisOkForm(false)
    setaddCtaData({
      ...addCtaData, [e.target.name]: e.target.value
    })
    
  }
  let isPssed = true
  const HandleSendAddCtaDataToServer = async () => {
    // isPssed = true
    // let list = Object.keys(addCtaData)
    // list.forEach(elmt => {
    //   if(addCtaData[elmt] === ''){
    //     isPssed = false
    //   }
    // });
    // if(!isPssed)alert('Debe Completar todos los campos')
    // else{
      /**standar de consulta a endpont con fetch */
      const listaCuenta = await fetch(url+'/fugatorio/addctaadmin',{
        method: 'PUT',
        headers: {
          'authorization': metadata.token,
          'aplicacion': 'FUGAMONIACO',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: addCtaData,

        })
      })
      let respons = await listaCuenta.json()

      /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
       * del session storage y se redirige al inicio
       */
      if(!respons.data.success){
        sessionStorage.clear()
        window.location.href = '/'
      }else{
        //window.location.reload()
        //console.log(respons.data)
        setReload(true)
        setState({'bottom': false})
        setisDeseable(false)
        setisOkForm(true)
        setaddCtaData({
          ...addCtaData,
          'nombre': '',
          'correo':'',
          'rut': '',
        })
      }
      /**standar de consulta a endpont con fetch */
      /** a continuacion ingrese la logica para una respons success por parte del endpoint */
    // }
  }

  return (
    <>
      <Button variant='contained' size='small' fullWidth onClick={toggleDrawer('bottom', true)} endIcon={<PersonAddAltOutlinedIcon />}>Agregar Cuenta</Button>
        <Drawer
          anchor='bottom'
          open={state['bottom']}
          onClose={toggleDrawer('bottom', false)}
        >
          <div className='p-3 mb-3'>
            <div className='container'>
              <div className='row'>
                <div className='vol-12'>
                  <h5>Agregar Cuenta Admin</h5>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" name='rut' value={addCtaData.rut} onChange={(e)=>HandleSetDataRut(e)} fullWidth size='small' label="Rut" variant="outlined" />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" disabled={isDeseable} name='nombre' value={addCtaData.nombre} onChange={(e)=>HandleSetAddCtaData(e)} fullWidth size='small' label="Nombre" variant="outlined"  />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" name='correo' value={addCtaData.correo} onChange={(e)=>HandleSetAddCtaData(e)} fullWidth size='small' label="Correo" variant="outlined"  />
                    </div>
                    
                    <div className='col-12 col-md-4 py-2'>
                      <Button variant='contained' disabled={isOkForm} color='info' size='medium' fullWidth onClick={()=>HandleSendAddCtaDataToServer()}>Crear Cuenta</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </Drawer>
  </>
  )
}
