
  var url_r = 'https://pasajeros.apidev.info/scan/consulta?rut='

  export default async function GetDataRut(rut) {
    //console.log('to get rut',rut)
    const response = fetch(url_r+rut,{method:'GET'})
    let res = await (await response).json()
    // console.log(res)
    if(await res[0]){
      return {
        success: true,
        data: {
          nom_corto: res[0].nom_corto,
          cargo: res[0].cargo,
          ceco: res[0].ceco,
          nom_ceco:res[0].nom_ceco,
          rut:res[0].rut
        }
      }
    }else{
      return {
        success: false,
        data: {
          rut:rut
        }
      }
    }
  }
  
