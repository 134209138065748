
import React, { useEffect, useState } from 'react'

import { url } from '../../../../varEntorn/Url.link'

import { Button, IconButton } from '@mui/material'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export default function Planta({metadata}) {
  const [DataList, setDataList] = useState([])

  const HandleSendDatoToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getplantasu',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO', 
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({
      //   data: 'formAdCta',
      // })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      setDataList(respons.data.body)
      
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    HandleSendDatoToServer()
  }, [])
  return (
    <div className='constainer'>
      <div className='row'>
        <div className='col-12 pb-3'>
          <Button size='small' variant='contained' fullWidth endIcon={<AddOutlinedIcon />}>Add Planta</Button>
        </div>
        <div className='col-12 table-responsive-sm'>
          <table className="table table-hover table-sm">
            <thead className='bg-dark text-white'>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Planta</th>
                <th scope="col" className='text-center'>Codigo</th>
                <th scope="col" className='text-center'>Estado</th>
                <th scope="col" className='text-center'>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                DataList.map((plt, index)=>
                  <tr key={index} className='trn'>
                    <td scope="row" className='trn'>{index+1}</td>
                    <td className=' trn'>{plt.NOMBRE}</td>
                    <td className='text-center trn'>{plt.CODIGO}</td>
                    <td className='text-center trn pt-2'>
                      {
                        plt.STATE?<CheckCircleOutlinedIcon color='success' />
                        :<DoNotDisturbAltOutlinedIcon color='error' />
                      }
                    </td>
                    <td className='text-center trn'>
                    <IconButton aria-label="edit" color='info' size='small'>
                      <SettingsOutlinedIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color='error' size='small'>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        <div className='col-12'></div>
      </div>
    </div>
  )
}
