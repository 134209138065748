import { IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { url } from '../../varEntorn/Url.link';

export default function DownloadFilen({metadata}) {
// console.log(metadata);
  const HandleQueryToServer = async () => {
    if(metadata.tuser==='megaUser'){
      window.location.href = url+'/fugatorio/download/all'
    }else{
      window.location.href = url+'/fugatorio/download/'+metadata.empresa
    }
  }

  return (
    <>
      <ListItem className='cursor' onClick={()=>HandleQueryToServer()}>
        <ListItemIcon><Inventory2OutlinedIcon /></ListItemIcon>
        <ListItemText className='text-white'>Descargar Reporte Fugas Registradas</ListItemText>
      </ListItem>
    </>
  )
}
