import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { GetOC } from '../helper/GetOC.api';
import { ChartOrdenCompra } from './ChartOrdenCompra';
import { PaginPages } from './PaginPages';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { DownloadReport } from '../helper/DownloadReport.api';
import { url} from '../../../../varEntorn/Url.link';
import { GetPlantasArr } from '../helper/GetPlantasArr.api';
import exportAsImage from '../helper/exportAsImage';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';

export const OrdenCompra = ({metadata}) => {
  const [ocList, setOcList] = useState([]);
  const [ocPaginate, setOcPaginate] = useState(null);
  const [listSelect, setListSelect] = useState([]);
  const exportRer = useRef();

  const getOc = async (pagina) => {
    if(pagina){
      let list = await GetOC({pagina,metadata,selectEmp})
      setOcList(list.docs)
      setOcPaginate(list.paginate)
    }else{
      pagina = 1
      let list = await GetOC({pagina,metadata,selectEmp})
      setOcList(list.docs)
      setOcPaginate(list.paginate)
    }
  }

  const DescargaReporte = async () => {
    let download = await DownloadReport({metadata})
    let url_ = url+download;
    let a = document.createElement("a");
    a.href = url_;
    a.download = "Control_Inyeccion.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  const GetPlantasToSelect = async () => {
    const ArrListEmp = await GetPlantasArr()
    setListSelect(ArrListEmp)
  }

  useEffect(() => {
    getOc();
    GetPlantasToSelect()
  }, [])

  // ****************************************************************
  const [selectEmp, setSelectEmp] = React.useState([]);
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectEmp(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  useEffect(() => {
    getOc();
  }, [selectEmp])
  
  
  return (
    <>
    <div className='container-fluid py-3' ref={exportRer}>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 col-md-3 py-2'>
              <Button variant='contained' onClick={()=>DescargaReporte()}>
                <span>
                  <Inventory2OutlinedIcon /> Descargar Reporte
                </span>
              </Button>
            </div>
            
            <div className='col-12 col-md-5 py-2' >
              {
                metadata.tuser==='megaUser'?
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-name-label" size='small'>Seleccione Planta(s)</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectEmp}
                    onChange={handleChange}
                    input={<OutlinedInput label="Seleccione Planta(s)" />}
                    // MenuProps={MenuProps}
                  >
                    {listSelect.map((name) => (
                      <MenuItem
                        key={name.codemp}
                        value={name._id}
                        // style={getStyles(name, personName, theme)}
                      >
                        {name._id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>:null
              }

              
            </div>

            <div className='col-12 col-md-4'>
              <p className='small float-right pr-2'>{ocPaginate?.totalDocs} Ordenes de compra encontradas en {ocPaginate?.totalPages} paginas.</p>
            </div>
          </div>
        </div>

        <div className='col-12 py-2'>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>N°OC</TableCell>
                  <TableCell>N°SPC</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell align="right">Fecha</TableCell>
                  <TableCell align="right">Recepcion</TableCell>
                  <TableCell align="right">Ceco</TableCell>
                  <TableCell align="right">Pedido</TableCell>
                  <TableCell align="right">Almacen</TableCell>
                  <TableCell align="right">Proveedor</TableCell>
                  {
                    metadata.tuser==='admin'?null:<TableCell align="right">Empresa</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {ocList.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{fontSize: '11px'}}>{row.NUM_OC}</TableCell>
                    <TableCell style={{fontSize: '11px'}}>{row.SPC}</TableCell>
                    <TableCell style={{fontSize: '11px'}}>{row.ESTADO_OC}</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.FECHA}</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.FEC_REC}</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.CECO}</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.ART_PEDIDO} kg</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.DESC_ALMACEN}</TableCell>
                    <TableCell align="right" style={{fontSize: '11px'}}>{row.DESC_PROV}</TableCell>
                    {
                      metadata.tuser==='admin'?null:<TableCell align="right" style={{fontSize: '11px'}}>{row.DESC_EMPRESA}</TableCell>
                    }

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>
        <div className='col-12'>
          {
            !ocPaginate?null:(
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {
                    ocPaginate.prevPage?(
                      <li className="page-item"  onClick={()=>getOc(ocPaginate.page-1)}>
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>):(
                      <li className="page-item disabled">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>)
                  }

                  <PaginPages paginacion={ocPaginate} getOc={(val)=>getOc(val)} />

                  {
                    ocPaginate.nextPage?(
                      <li className="page-item" onClick={()=>getOc(ocPaginate.page+1)}>
                        <a className="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    ):(
                      <li className="page-item disabled">
                        <a className="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    )
                  }
                  
                </ul>
              </nav>
            )
          }
        </div>
        <div className='col-12'>
          <ChartOrdenCompra metadata={metadata} selectEmp={selectEmp} />
        </div>
      </div>
    </div>
    {/* Button to Take a ScreenShot */}
    <div className='container-fluid mb-3 pb-2'>
      <div className='row'>
        <div className='col-12 text-right'>
          <Button
            variant="contained"
            onClick={()=>exportAsImage(exportRer.current, "scrst_ctrlAmoniaco")}
            color='secondary'
            sx={{minWidth: 200}}
          >
            <LinkedCameraIcon />
          </Button>
        </div>
      </div>
    </div>
    </>
  )
}

// ANO_CONT: "2018"
// ART_PEDIDO: 3140
// ART_RECIBIDO: 3140
// A_MES_CONT: "Oct '18"
// A_MES_OC: "Sep '18"
// AÑO_OC: "2018"
// CECO: "00080069"
// COD_ALMACEN: ""
// COD_PROD: "174052"
// COMENTARIO: ""
// COMPRADOR: "CVALDES"
// COSTO_PEDIDO: 5463600
// COSTO_RECIBIDO: 5463600
// DESC_ALMACEN: "ALMACEN REPUESTOS EL PAICO"
// DESC_CECO: ""
// DESC_EMPRESA: "AGROINDUSTRIAL EL PAICO S.A."
// DESC_PROD: "AMONIACO ANHIDRO P/REFIGERACION"
// DESC_PROV: "INDUSTRIAS QUIMICAS CLORAMON LTDA."
// EMPRESA:"15"
// ESTADO_OC: "85"
// ETA: "2018-10-17T00:00:00.000Z"
// ETA_: "17-10-2018"
// FACTURA: "F/112526"
// FECHA: "01-10-2018"
// FECHA_OC: "2018-10-01T00:00:00.000Z"
// FECHA_RECEPCION: "2018-10-17T00:00:00.000Z"
// FEC_REC: "17-10-2018"
// GRUPO_PROD: "PRODUCTOS QUIMICOS"
// ID_OC: "83019500_1570665_2_174052"
// LINEA_OC: "2"
// MES_CONT: "10"
// MES_OC: "9"
// MONEDA_VU: "PESOS"
// NUM_OC: "1570665"
// RUT_PROV: "83019500"
// SEMANA_OC: "40"
// SPC: "2018641"
// ST_LINEA: ""
// UNI_MEDIDA: "KILO"
// VALOR_UNIT: 0
