import { Button, Drawer, IconButton, MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { url } from '../../../varEntorn/Url.link';

export default function EditCta({usuario, metadata, setReload}) {
  // console.log(usuario)
  const [state, setState] = useState({bottom: false})
  const [editData, setEditData] = useState({
    'rut_': usuario.rut,
    'nombre_': usuario.nombre,
    'estado_': usuario.state,
    'correo_': usuario.user,
    'id': usuario._id
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandeSetEditData = async (e) => {
    setEditData({
      ...editData, [e.target.name]: e.target.value
    })
  }

  const HandleSendDataEditToServer = async () => {
     /**standar de consulta a endpont con fetch */
     const listaCuenta = await fetch(url+'/fugatorio/edituseradmin',{
      method: 'PUT',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: editData
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      console.log(respons.data)
      // setUsuarios(respons.data.body)
      setReload(true)
      setState({bottom: false})
      // window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  return (
    <>
      <IconButton size='small' color='info' onClick={toggleDrawer('bottom', true)}>
        <ManageAccountsOutlinedIcon />
      </IconButton>
        <Drawer
          anchor='bottom'
          open={state['bottom']}
          onClose={toggleDrawer('bottom', false)}
        >
          <div className='p-3 mb-3'>
            <div className='container'>
              <div className='row'>
                <div className='vol-12'>
                  <h5>Editar Cuenta</h5>
                </div>
                <div className='col-12'>
                   <div className='row'>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" name='rut_' onChange={(e)=>HandeSetEditData(e)} value={editData.rut_} fullWidth size='small' label="Rut" variant="outlined"  />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" name='nombre_' onChange={(e)=>HandeSetEditData(e)} value={editData.nombre_} fullWidth size='small' label="Nombre" variant="outlined" />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Estado"
                        // value={currency}
                        // onChange={handleChange}
                        fullWidth
                        size='small'
                        name='estado_'
                        value={editData.estado_}
                        onChange={(e)=>HandeSetEditData(e)}
                      >
                        {/* <MenuItem key={option.value} value={option.value}>planta 1</MenuItem> */}
                        <MenuItem value={true}>activo</MenuItem>
                        <MenuItem value={false}>inactivo</MenuItem>
                      </TextField>
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" name='correo_' onChange={(e)=>HandeSetEditData(e)} value={editData.correo_} fullWidth size='small' label="Correo" variant="outlined"  />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <Button variant='contained' color='info' size='medium' fullWidth onClick={()=>HandleSendDataEditToServer()}>Editar Cuenta</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </Drawer>
  </>
  )
}
