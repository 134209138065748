import React, { useEffect, useState } from 'react'

import { url } from '../../../../varEntorn/Url.link'

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';

export default function PuntoFuga({metadata}) {
  // console.log(metadata.empresa)
  const [DataList, setDataList] = useState([])

  const HandleSendDatoToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getpuntofugasu',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO', 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        planta: metadata.empresa,
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      // console.log(respons.data.body)
      setDataList(respons.data.body)
      
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    HandleSendDatoToServer()
  }, [])
  return (
    <div className='constainer'>
      <div className='row'>
        <div className='col-12 pb-3'>
        </div>
        <div className='col-12 table-responsive-sm'>
          <table className="table table-hover table-sm">
            <thead className='bg-dark text-white'>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Planta</th>
                <th scope="col" className='text-center'>Punto de Fuga</th>
                <th scope="col" className='text-center'>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                DataList.map((plt, index)=>
                  <tr key={index} className='trn'>
                    <td scope="row" className='trn'>{index+1}</td>
                    <td className=' trn'>{metadata.empresa}</td>
                    <td className='text-center trn'>{plt.NOMBRE}</td>
                    <td className='text-center trn pt-2'>
                      {
                        plt.STATE?<CheckCircleOutlinedIcon color='success' />
                        :<DoNotDisturbAltOutlinedIcon color='error' />
                      }
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        <div className='col-12'></div>
      </div>
    </div>
  )
}
