import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, TextField } from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import React, { useEffect, useState } from 'react'
import { url } from '../../varEntorn/Url.link'
import EditData from './component/EditData.component';
import SearchIcon from '@mui/icons-material/Search';


export default function DashBoarDatos({metadata}) {
  const [IsReload, setIsReload] = useState(false)
  const [DataEvent, setDataEvent] = useState([])
  const [SelectFecha, setSelectFecha] = useState(0);
  const [ValueSelectFecha, setValueSelectFecha] = useState([]);
  const [SelectPlantaValue, setSelectPlantaValue] = useState(metadata.empresa);
  const HandleSetFechaValue = async (e) => {
    setSelectFecha(e.target.value)
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const HandleMutaSelectFecha = async () => {
    let a = new Date()
    let b = (a.getMonth()+1)
    let b2 = (a.getMonth()+1)<=9?'0'+(a.getMonth()+1):(a.getMonth()+1)
    let c = a.getFullYear()
    let g = c+'-'+b2
    await setSelectFecha(g)
    // console.log(c,b);
    let arr = []
    let y = ~~c
    let m = b
    for (let n = 12; n > 0; n--) {
      if(m>0){
        let p = m<=9?'0'+m:m
        arr.push(y+'-'+p)
         m = m-1
      }
      if(m===0){
        y = ~~c-1
        arr.push(y+'-'+12)
        m = 11
      }
    }
    // await console.log(arr);
    setSelectFecha(g);
    await setValueSelectFecha(arr)
  }


  const fetchulta = async () => {
    // console.log('WATCH',SelectFecha);
    setDataEvent([])
    if(SelectFecha === 0){
      return
    }
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/geteventos',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {empresa: SelectPlantaValue},
        fecha: SelectFecha
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      // console.log(respons.data)
      setDataEvent(respons.data.body)
      setIsReload(false)
      // window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(async() => {
    await HandleMutaSelectFecha()
    await fetchulta()
  }, [])

  useEffect(async() => {
    await fetchulta()
  }, [IsReload, SelectFecha])

  return (
    <>
      <div className='container fondo-div mt-3 py-4'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <h3 className='t-shadow'>Datos</h3>
          </div>
          <div className='col-12 col-md-5 pt-3'>
            <TextField
              id="outlined-select-currency"
              select
              label="Fecha"
              value={SelectFecha}
              onChange={(e)=>HandleSetFechaValue(e)}
              name='fechas'
              fullWidth
              size='small'
            >
              {ValueSelectFecha.map((index, itm) => 
                <MenuItem key={itm} value={index}>{index}</MenuItem>
              )}
            </TextField>
          </div>
          <div className='col-12 col-md-3 pt-3'>
            <Button color='info' variant='contained' onClick={()=>fetchulta()} endIcon={<SearchIcon />}>Buscar</Button>
          </div>
        </div>
      </div>
    
    {
      DataEvent.length!==0?
      <>
      <div className='container-fluid fondo-div my-5 py-5 hidden-xs hidden-sm'>
      <div className='row'>
        <div className=' col-12 table-responsive'>
          <table className="table table-sm table-hover">
            <thead className='bg-dark text-white'>
              <tr>
                <th scope="col">#</th>
                <th scope="col"><small>Tipo</small></th>
                <th scope="col"><small>Fecha Evento</small></th>
                <th scope="col"><small>Area</small></th>
                <th scope="col"><small>Linea</small></th>
                <th scope="col"><small>Punto Fuga</small></th>
                <th scope="col"><small>Metodo Deteccion</small></th>
                <th scope="col"><small>Duracion</small></th>
                <th scope="col"><small>Concentracion</small></th>
                <th scope="col"><small>Fecha Cierre</small></th>
                <th scope="col"><small>Tecnico</small></th>
                <th scope="col"><small>Empresa</small></th>
                <th scope="col"><small>Coordenada</small></th>
              </tr>
            </thead>
            <tbody>
              
              {
                DataEvent.map((itm, index)=>
                  <tr key={index}>
                    <th scope="row"><small></small>{index+1}</th>
                    <td><small>{itm.TIPO}</small></td>
                    <td><small>{itm.FECHA_EVENTO}</small></td>
                    <td><small>{itm.AREA}</small></td>
                    <td><small>{itm.LINEA}</small></td>
                    <td><small>{itm.PUNTO_FUGA}</small></td>
                    <td><small>{itm.METODO_DETECCION}</small></td>
                    <td><small>{itm.DURACION} min</small></td>
                    <td><small className='text-danger'>{itm.CONCENTRACION} ppm</small></td>
                    <td><small>{itm.FECHA_CIERRE}</small></td>
                    <td><small>{itm.OPERADOR}</small></td>
                    <td><small>{itm.EMPRESA}</small></td>
                    <td><small>{itm.COORDENADA}</small></td>
                    {/* <td><EditData metadata={metadata} valores={itm} setIsReload={setIsReload} /></td> */}
                  </tr>
                )
              }
              
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div className='container fondo-div my-5 py-5 hidden-md hidden-lg'>
      <div className='row'>
        <div className='col'>
          {
            DataEvent.map((itm,index)=>
              <Accordion className='p-0 m-0 border' key={index} expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                  expandIcon={<KeyboardDoubleArrowDownIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className=''
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 col-md-6'><small><b>{index+1}.- {itm.FECHA_EVENTO}</b></small></div>
                      <div className='col-12 col-md-6'><small><b>{itm.TIPO} EN {itm.AREA}</b></small></div>
                    </div>
                  </div>
                  
                </AccordionSummary>
                <AccordionDetails className=''>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <div className='row'>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Fecha Evento: <b>{itm.FECHA_EVENTO}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Evento: <b>{itm.TIPO}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Area: <b>{itm.AREA}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Linea: <b>{itm.LINEA}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Punto Fuga: <b>{itm.PUNTO_FUGA}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Metodo Deteccion: <b>{itm.METODO_DETECCION}</b></small></div>
                        </div>
                      </div>
                      <div className='col- col-md-6'>
                        <div className='row'>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Duracion: <b>{itm.DURACION}</b> minutos</small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Concentracion: <b className='text-danger'>{itm.CONCENTRACION} ppm</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Fecha Cierre: <b>{itm.FECHA_CIERRE}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Tecnico: <b>{itm.OPERADOR}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Empresa: <b>{itm.EMPRESA}</b></small></div>
                          <div className='col-12 border border-right-0 border-top-0 my-1 border-dark'><small>Coordenada Plano: <b>{itm.COORDENADA}</b></small></div>
                        </div>
                      </div>
                        <div className='btn-edit-data'>
                          <EditData metadata={metadata} valores={itm} setIsReload={setIsReload} />
                        </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          }
          
         
        </div>
      </div>
    </div></>
      :<div className='container text-center fondo-div py-5 mt-5'><span>Sin datos Para Mostrar</span></div>
    }
    
     
    </>
  )
}
