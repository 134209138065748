import React from 'react'

export default function DashboardData() {
  return (
    <div className='container fondo-div my-5 text-center'>
      <p className='display-1'>DASHBOARD</p>
      <span className='display-1'> proximamente </span>
    </div>
  )
}
