import { Drawer, TextField } from '@mui/material';
import React, { useState } from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { url } from '../../../../../varEntorn/Url.link';

export default function Add({metadata, setIsReload, campo}) {
  // console.log(metadata)
  const [state, setState] = useState({bottom: false});
  const [isDeseable, setisDeseable] = useState(true)
  const [nameData, setnameData] = useState('')
  const [isLoading, setisLoading] = useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleSetData = async (e) => {
    //console.log(e.target.name, e.target.value)
    setnameData(e.target.value)
    if(e.target.value.length >= 3){
      setisDeseable(false)
    }else{
      setisDeseable(true)
    }
  }

  const HandleSendDatoToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/additemad',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO', 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: nameData,
        campo: campo,
        empresa: metadata.empresa
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      //setDataList(respons.data.body)
      setnameData('')
      setState({bottom: false})
      setIsReload(true)
      setisDeseable(true)
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  return (
    <div>
      <button className="btn  btn-sm btn-secondary w-100" onClick={toggleDrawer('bottom', true)}> Crear Nuevo Item <AddOutlinedIcon  /></button>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        <div className="container table-responsive pb-5">
          <div className="row">
            <div className="col-12 py-3"><h5>Crear Nuevo Item</h5></div>

            <div className="col-12 py-2">
              <TextField fullWidth
                id="outlined-basic"
                name="NOMBRE"
                label="Nombre"
                size="small"
                variant="outlined"
                value={nameData}
                onChange={(e)=>HandleSetData(e)}
              />
            </div>
            <div className="col-12 py-2"> 
              <button className="btn btn-info " disabled={isDeseable} onClick={()=>HandleSendDatoToServer()}>
                {
                  isLoading?"Cargando":"Crear"
                }
                </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
