import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';

export default function AddData() {
  const [currency, setCurrency] = useState({
    'fecha_inicio': '',
    'hora_fin': '',
    'linea': '',
    'conjunto': '',
    'tecnico': '',
    'metodo_deteccion': '',
    'observacion': ''
  });

  const handleChange = (event) => {
    setCurrency({
      ...currency, [event.target.name]: event.target.value
    });
  };
  let getDatatime_Inicio = async () => {
    let fecha = new Date()
    let dd = fecha.getDate()<10?'0'+fecha.getDate():fecha.getDate()
    let mes = (fecha.getMonth()+1)<10?'0'+(fecha.getMonth()+1):(fecha.getMonth()+1)
    let yyyy = fecha.getFullYear()
    let hrs = fecha.getHours()
    let min = fecha.getMinutes()<10?'0'+fecha.getMinutes():fecha.getMinutes()
    let isToTime = fecha.toString().split(' ')[4]
    fecha = fecha.toISOString()
    let isfecha = yyyy+'-'+mes+'-'+dd+'T'+hrs+':'+min
    setCurrency(
      {...currency, ['fecha_inicio']: isfecha, ['hora_fin']: isfecha}
    )
  }

  useEffect(() => {
    getDatatime_Inicio()
  }, [])

  return (
    <>
    <div className='container my-3'>
      <div className='row'>
        <div className='col-12 fondo-div py-3'>
          <h1>Ingreso Evento</h1>
          <small>Formulario Basado en el archivo excel que llevan en la actualidad para los eventos de fugas</small>
        </div>
      </div>
    </div>
    <div className='container'>
      <div className='row fondo-div py-5'>
        <div className='col-12 col-md-6 my-2'>
          <TextField id="outlined-basic" type="datetime-local" name='fecha_inicio' fullWidth label="Fecha" min='today' value={currency.fecha_inicio} onChange={handleChange} variant="outlined" size='small' />
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField
          id="outlined-select-currency"
          select
          label="Conjunto"
          name="conjunto"
          value={currency.conjunto}
          onChange={handleChange}
          size='small'
          fullWidth
        >
          <MenuItem value='CMRA 201 PONIENTE'>CMRA 201 PONIENTE</MenuItem>
          <MenuItem value='CMRA 201 ORIENTE'>CMRA 201 ORIENTE</MenuItem>
          <MenuItem value='CMRA 207'>CMRA 207</MenuItem>
          <MenuItem value='CMRA 202'>CMRA 202</MenuItem>
          <MenuItem value='CMRA 304 7B'>CMRA 304 7B</MenuItem>
          <MenuItem value='CMRA 304 7A'>CMRA 304 7A</MenuItem>
          <MenuItem value='CMRA 304 6A'>CMRA 304 6A</MenuItem>
          <MenuItem value='CMRA 304 6B'>CMRA 304 6B</MenuItem>
          <MenuItem value='CMRA 304 5B'>CMRA 304 5B</MenuItem>
          <MenuItem value='CMRA 304 5A'>CMRA 304 5A</MenuItem>
          <MenuItem value='CMRA 208 A'>CMRA 208 A</MenuItem>
          <MenuItem value='CMRA 208 B'>CMRA 208 B</MenuItem>
          <MenuItem value='CMRA 208 C'>CMRA 208 C</MenuItem>
          <MenuItem value='CMRA 208 D'>CMRA 208 D</MenuItem>
          <MenuItem value='CMRA 290'>CMRA 290</MenuItem>
          <MenuItem value='TÚNEL 1'>TÚNEL 1</MenuItem>
          <MenuItem value='TÚNEL 23'>TÚNEL 23</MenuItem>
          <MenuItem value='TÚNEL 4'>TÚNEL 4</MenuItem>
          <MenuItem value='TÚNEL 56'>TÚNEL 56</MenuItem>
          <MenuItem value='TÚNEL 78'>TÚNEL 78</MenuItem>
          <MenuItem value='TÚNEL 9'>TÚNEL 9</MenuItem>
          <MenuItem value='TÚNEL 10'>TÚNEL 10</MenuItem>
          <MenuItem value='TÚNEL 11'>TÚNEL 11</MenuItem>
          <MenuItem value='TÚNEL 12'>TÚNEL 12</MenuItem>
          <MenuItem value='CONGELADOR ITALIANO'>CONGELADOR ITALIANO</MenuItem>
          <MenuItem value='CONGELADOR DSI NORTE'>CONGELADOR DSI NORTE</MenuItem>
          <MenuItem value='CONGELADOR DSI SUR'>CONGELADOR DSI SUR</MenuItem>
          <MenuItem value='CONGELADOR TUCAL'>CONGELADOR TUCAL</MenuItem>
          <MenuItem value='INTERCAMBIADOR VOTATOR'>INTERCAMBIADOR VOTATOR</MenuItem>
          <MenuItem value='CONGELADOR IQF 1'>CONGELADOR IQF 1</MenuItem>
          <MenuItem value='CONGELADOR IQF 4'>CONGELADOR IQF 4</MenuItem>
          <MenuItem value='COMPRESOR 1028'>COMPRESOR 1028</MenuItem>
          <MenuItem value='COMPRESOR 1104'>COMPRESOR 1104</MenuItem>
          <MenuItem value='COMPRESOR 1030'>COMPRESOR 1030</MenuItem>
          <MenuItem value='COMPRESOR 1031'>COMPRESOR 1031</MenuItem>
          <MenuItem value='COMPRESOR 1057'>COMPRESOR 1057</MenuItem>
          <MenuItem value='COMPRESOR 1060'>COMPRESOR 1060</MenuItem>
          <MenuItem value='COMPRESOR 1063'>COMPRESOR 1063</MenuItem>
          <MenuItem value='COMPRESOR 1069'>COMPRESOR 1069</MenuItem>
          <MenuItem value='COMPRESOR 1072'>COMPRESOR 1072</MenuItem>
          <MenuItem value='COMPRESOR 1106'>COMPRESOR 1106</MenuItem>
          <MenuItem value='COMPRESOR 1124'>COMPRESOR 1124</MenuItem>
          <MenuItem value='LÍNEA GENERAL LÍQUIDO'>LÍNEA GENERAL LÍQUIDO</MenuItem>
          <MenuItem value='LÍNEA GENERAL DESCARGA'>LÍNEA GENERAL DESCARGA</MenuItem>
          <MenuItem value='SCANBRINE'>SCANBRINE</MenuItem>
          <MenuItem value='OSMOSIS'>OSMOSIS</MenuItem>
          <MenuItem value='SALMUERA'>SALMUERA</MenuItem>
          <MenuItem value='INTERCAMBIADOR GLICOL N°1'>INTERCAMBIADOR GLICOL N°1</MenuItem>
          <MenuItem value='INTERCAMBIADOR GLICOL N°2'>INTERCAMBIADOR GLICOL N°2</MenuItem>
          <MenuItem value='INTERCAMBIADOR GLICOL N°3'>INTERCAMBIADOR GLICOL N°3</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°1'>CHILLER DE AIRE CONJUNTO N°1</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°2'>CHILLER DE AIRE CONJUNTO N°2</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°3'>CHILLER DE AIRE CONJUNTO N°3</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°4'>CHILLER DE AIRE CONJUNTO N°4</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°5'>CHILLER DE AIRE CONJUNTO N°5</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°6'>CHILLER DE AIRE CONJUNTO N°6</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°7'>CHILLER DE AIRE CONJUNTO N°7</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°8'>CHILLER DE AIRE CONJUNTO N°8</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°9'>CHILLER DE AIRE CONJUNTO N°9</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°10'>CHILLER DE AIRE CONJUNTO N°10</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°11'>CHILLER DE AIRE CONJUNTO N°11</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°12'>CHILLER DE AIRE CONJUNTO N°12</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°13'>CHILLER DE AIRE CONJUNTO N°13</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°14'>CHILLER DE AIRE CONJUNTO N°14</MenuItem>
          <MenuItem value='CHILLER DE AIRE CONJUNTO N°15'>CHILLER DE AIRE CONJUNTO N°15</MenuItem>
          <MenuItem value='MADEF 1'>MADEF 1</MenuItem>
          <MenuItem value='MADEF 2'>MADEF 2</MenuItem>
          <MenuItem value='MADEF 3'>MADEF 3</MenuItem>
          <MenuItem value='MADEF 4'>MADEF 4</MenuItem>
          <MenuItem value='BANCO DE AGUA A'>BANCO DE AGUA A</MenuItem>
          <MenuItem value='BANCO DE AGUA B'>BANCO DE AGUA B</MenuItem>
          <MenuItem value='BANCO DE AGUA C'>BANCO DE AGUA C</MenuItem>
          <MenuItem value='RECIRCULADOR ALTA N°1'>RECIRCULADOR ALTA N°1</MenuItem>
          <MenuItem value='RECIRCULADOR ALTA N°2'>RECIRCULADOR ALTA N°2</MenuItem>
          <MenuItem value='RECIRCULADOR BAJA N°1'>RECIRCULADOR BAJA N°1</MenuItem>
          <MenuItem value='RECIRCULADOR BAJA N°2'>RECIRCULADOR BAJA N°2</MenuItem>
          <MenuItem value='RECIRCULADOR BAJA N°3'>RECIRCULADOR BAJA N°3</MenuItem>
          <MenuItem value='RECIRCULADOR IQF'>RECIRCULADOR IQF</MenuItem>
          <MenuItem value='TRAMPA DE SUCCIÓN'>TRAMPA DE SUCCIÓN</MenuItem>
          <MenuItem value='RECIBIDOR DE LÍQUIDO PRIMARIO'>RECIBIDOR DE LÍQUIDO PRIMARIO</MenuItem>
          <MenuItem value='RECIBIDOR DE LÍQUIDO SECUNDARIO'>RECIBIDOR DE LÍQUIDO SECUNDARIO</MenuItem>
        </TextField>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField
          id="outlined-select-currency"
          select
          label="Linea"
          name='linea'
          value={currency.linea}
          onChange={handleChange}
          size='small'
          fullWidth
        >
          <MenuItem value='SUCCIÓN'>SUCCIÓN</MenuItem>
          <MenuItem value='LÍQUIDO'>LÍQUIDO</MenuItem>
          <MenuItem value='GAS CALIENTE'>GAS CALIENTE</MenuItem>
          <MenuItem value='RETORNO SUCCIÓN'>RETORNO SUCCIÓN</MenuItem>
          <MenuItem value='RETORNO GAS CALIENTE'>RETORNO GAS CALIENTE</MenuItem>
          <MenuItem value='RETORNO LÍQUIDO'>RETORNO LÍQUIDO</MenuItem>

        </TextField>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField
          id="outlined-select-currency"
          select
          label="Tecnico"
          name='tecnico'
          value={currency.tecnico}
          onChange={handleChange}
          size='small'
          fullWidth
        >
          <MenuItem value='ALAN AROS'>ALAN AROS</MenuItem>
          <MenuItem value='MARIO BUSTOS'>MARIO BUSTOS</MenuItem>
          <MenuItem value='ÁNGEL CORNEJO'>ÁNGEL CORNEJO</MenuItem>
          <MenuItem value='FERNANDO HERRERA'>FERNANDO HERRERA</MenuItem>
          <MenuItem value='SERGIO LETELIER'>SERGIO LETELIER</MenuItem>
          <MenuItem value='MAURICIO MAYOLAFQUÉN'>MAURICIO MAYOLAFQUÉN</MenuItem>
          <MenuItem value='ANDRÉS MELILLÁN'>ANDRÉS MELILLÁN</MenuItem>
          <MenuItem value='CARLOS NÚÑEZ'>CARLOS NÚÑEZ</MenuItem>
        </TextField>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField
          id="outlined-select-currency"
          select
          label="Metodo de Deteccion"
          name='metodo_deteccion'
          value={currency.metodo_deteccion}
          onChange={handleChange}
          size='small'
          fullWidth
        >
          <MenuItem value='MECHA DE AZUFRE'>MECHA DE AZUFRE</MenuItem>
          <MenuItem value='DETECTOR PORTÁTIL'>DETECTOR PORTÁTIL</MenuItem>
          <MenuItem value='SENSORES NH3'>SENSORES NH3</MenuItem>
          <MenuItem value='OLOR EN AMBIENTE'>OLOR EN AMBIENTE</MenuItem>
          <MenuItem value='OTRO'>OTRO</MenuItem>
        </TextField>
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField id="outlined-basic" type="datetime-local" name='hora_fin' value={currency.hora_fin} fullWidth label="Hora Reparacion" onChange={handleChange} variant="outlined" size='small' />
        </div>
        <div className='col-12 col-md-6 my-2'>
          <TextField
            id="outlined-multiline-flexible"
            label="Observacion"
            multiline
            name='observacion'
            onChange={handleChange}
            maxRows={4}
            fullWidth
            size='small'
          />
        </div>
        <div className='col-12 col-md-6 my-2 text-center'>
          <Button variant="contained" fullWidth color='error' size='medium' onClick={()=>console.log(currency)}>Guardar Evento</Button>
        </div>
      </div>
    </div>
    </>
  )
}
