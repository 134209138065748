import React from 'react'

export default function Index2() {
  return (
    <div>
      <h1>contenido</h1>
      <a href='./sessionclose'>Salir</a>
    </div>
  )
}
