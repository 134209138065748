import React from 'react'
import { OrdenCompra } from './ctrl_amoniaco/component/OrdenCompra'

export const DashBoardAmoniaco = ({metadata}) => {
  return (
    <>
    {/* <div>DashBoardAmoniaco</div> */}
    <div className='fondo mt-3'>
      <OrdenCompra metadata={metadata} />
    </div>
    </>
  )
}
