import { Button, Drawer, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { url } from '../../../varEntorn/Url.link';
import GetDataRut from '../../extApi/GetRut.api';

export default function AddCta({metadata, setReload}) {
  
  const [state, setState] = useState({bottom: false,})
  const [planta, setPlanta] = useState([])
  const [formAdCta, setFormAdCta] = useState({
    'planta': metadata.empresa,
    'rut': '',
    'nombre': '',
    'correo': '',
    'perfil': '',
    'state': true,
    'aplicaciones': 'FUGAMONIACO'
  })

  let isPssed = true
  const [isDeseable, setisDeseable] = useState(false)
  const [isOkForm, setisOkForm] = useState(true)

  const HandleSetFormAdCta = async (e) => {
    // console.log(e.target.name, e.target.value)
    await setFormAdCta({
      ...formAdCta,[e.target.name]: e.target.value
    })
    isPssed = true
    let list = await Object.keys(formAdCta)
    list.forEach(elmt => {
      if(formAdCta[elmt] === ''){
        isPssed = false
      }
    });
    if(isPssed)setisOkForm(false)
   
  }

  const HandleSetDataRut = async (e) => {
    let rut_ = e.target.value
    var clean = rut_.replace(/-/g,"")
    var largo = clean.length
    var prin = clean.substr(0,(largo-1));
    var nal = clean.substr((largo-1),largo)
    rut_ = prin+"-"+nal
    //console.log(rut_)
    setFormAdCta({...formAdCta,['rut']:rut_})
    // console.log(rut_.length)
    if(rut_.length >=10){
      let metarut = await GetDataRut(rut_)
      if(metarut.success){
        setisDeseable(true)
        setFormAdCta({
          ...formAdCta, ['nombre']: metarut.data.nom_corto, ['rut']: metarut.data.rut
        })
      }else{
        setisDeseable(false)
        setFormAdCta({
          ...formAdCta,['nombre']: '', ['rut']: metarut.data.rut
        })
      }
      return
    }
  }

  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getplantas',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: 'parametros',

      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      // console.log(respons.data)
      await setPlanta(respons.data.body)
      // window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    fetchulta()
  }, [])

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleSendDatoToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/addctasu',{
      method: 'PUT',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: formAdCta,
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      //console.log(respons.data)
      setReload(false)
      setState({'bottom': false})
      setisDeseable(false)
      setisOkForm(true)
      setFormAdCta({
        ...formAdCta,
        'planta': metadata.empresa,
        'rut': '',
        'nombre': '',
        'correo': '',
        'perfil': '',
      })
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  return (
    <>
      <Button variant='contained' size='small' fullWidth onClick={toggleDrawer('bottom', true)} endIcon={<PersonAddAltOutlinedIcon />}>Agregar Cuenta</Button>
        <Drawer
          anchor='bottom'
          open={state['bottom']}
          onClose={toggleDrawer('bottom', false)}
        >
          <div className='p-3 mb-3'>
            <div className='container'>
              <div className='row'>
                <div className='vol-12'>
                  <h5>Agregar Cuenta</h5>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    {
                      metadata.tuser==="megaUser"?
                        <div className='col-12 col-md-4 py-2'>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Planta"
                            value={formAdCta.planta}
                            onChange={(e)=>HandleSetFormAdCta(e)}
                            name='planta'
                            fullWidth
                            size='small'
                          >
                            {planta.map((index, itm) => 
                              <MenuItem key={itm} value={index.NOMBRE}>{index.NOMBRE}</MenuItem>
                            )}
                          </TextField>
                        </div>
                      :null
                    }
                    
                    <div className='col-12 col-md-4 py-2'>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Perfil"
                        value={formAdCta.perfil}
                        onChange={(e)=>HandleSetFormAdCta(e)}
                        fullWidth
                        size='small'
                        name='perfil'
                      >
                        {/* <MenuItem key={option.value} value={option.value}>planta 1</MenuItem> */}
                        {
                          metadata.tuser==="megaUser"?<MenuItem value="superUser">Super Usuario</MenuItem>
                          :null
                        }
                        <MenuItem value="admin">Administrador</MenuItem>
                        <MenuItem value="user">Usuario</MenuItem>
                      </TextField>
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" onChange={(e)=>HandleSetDataRut(e)} name='rut' value={formAdCta.rut} fullWidth size='small' label="Rut" variant="outlined" />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" onChange={(e)=>HandleSetFormAdCta(e)} disabled={isDeseable} name='nombre' value={formAdCta.nombre} fullWidth size='small' label="Nombre" variant="outlined"  />
                    </div>
                    
                    <div className='col-12 col-md-4 py-2'>
                      <TextField id="outlined-basic" onChange={(e)=>HandleSetFormAdCta(e)} name='correo' value={formAdCta.correo} fullWidth size='small' label="Correo" variant="outlined"  />
                    </div>
                    <div className='col-12 col-md-4 py-2'>
                      <Button variant='contained' disabled={isOkForm} color='info' size='medium' onClick={()=>HandleSendDatoToServer()}
                       fullWidth>Crear Cuenta</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </Drawer>
  </>
  )
}
