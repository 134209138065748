import { Button, MenuItem, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import SelectPlanta from '../superUser/component/SelectPlanta.select.component';
import Grafico from './component/Grafico.component';
import exportAsImage from './ctrl_amoniaco/helper/exportAsImage';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';

export default function Graficar({metadata}) {
  //console.log(metadata);

  const [valorSelectChart, setValorSelectChart] = useState([]);
  const [selectPlanta, setSelectPlantaValue] = useState();
  const exportRer = useRef();

  const options = [
    { value: 'LINEA', label: 'Linea' },
    { value: 'AREA', label: 'Area' },
    { value: 'CONCENTRACION', label: 'Concentracion' },
    { value: 'TIPO', label: 'Tipo' },
    { value: 'PUNTO_FUGA', label: 'Punto de Fuga' },
    { value: 'FSHORT', label: 'Fecha' },
    { value: 'OPERADOR', label: 'Tecnico' },
    { value: 'RESUMEN', label: 'Resumen' },
  ]

  const HandleChangeValue = async (e) => {
    setValorSelectChart(e.target.value)
  }


  return (
    <>
      {/* <div className='container fondo-div my-3 p-3'>
        <div className='row'>
          <div className='col'>
            <h1>Graficos</h1>
          </div>
        </div>
      </div> */}
      <div className='container fondo-div my-3 p-3'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='t-shadow'>Grafico</h3>
          </div>
          {
            metadata.tuser==="megaUser"?
            <div className='col-12 col-md-6 my-2'>
              <SelectPlanta metadata={metadata} setSelectPlantaValue={setSelectPlantaValue} />
            </div>
            :null
          }
          
          <div className='col-12 col-md-6 my-2'>
            <TextField
              id="outlined-select-currency"
              select
              label="Orden"
              value={valorSelectChart}
              onChange={(e)=>HandleChangeValue(e)}
              name='Orden'
              fullWidth
              size='small'
            >
              {options.map((index, itm) => 
                <MenuItem key={itm} value={index.value}>{index.label}</MenuItem>
              )}
            </TextField>
          </div>
        </div>
      </div>
      <div className='container-fluid fondo-div my-3 p-3'>
        <div className='row'>
          <div className='col-12' ref={exportRer}>
            <Grafico metadata={metadata} empresa={selectPlanta||metadata.empresa} valor={valorSelectChart} />
          </div>
        </div>
      </div>

      {/* Button to Take a ScreenShot */}
      <div className='container-fluid mb-3 pb-2'>
        <div className='row'>
          <div className='col-12 text-right'>
            <Button
              variant="contained"
              onClick={()=>exportAsImage(exportRer.current, 'grafico-fugas')}
              color='secondary'
              sx={{minWidth: 200}}
            >
              <LinkedCameraIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

