import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export const ChartLine = ({titleChart, data}) => {
  if(data){

    const options = {
      chart: {
          type: 'column',
          scrollablePlotArea: {
            minWidth: 600,
            scrollPositionX: 1
        }
      },
      title: {
          text: titleChart
      },
      // subtitle: {
      //     text: 'Source: ' +
      //         '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
      //         'target="_blank">Wikipedia.com</a>'
      // },
      xAxis: {
          categories: data.categories,
          accessibility: {
              description: 'Historial de compra Amoniaco'
          }
      },
      yAxis: {
          title: {
              text: 'Cantidad'
          },
          labels: {
              formatter: function () {
                  return this.value + 'kg';
              }
          }
      },
      tooltip: {
          crosshairs: true,
          shared: true
      },
      plotOptions: {
          spline: {
              marker: {
                  radius: 5,
                  lineColor: '#666666',
                  lineWidth: 2
              }
          }
      },
      series: data.series
  }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </>
    )
  } else {
    <h5>Cargando...</h5>
  }

}
