import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { url } from '../../varEntorn/Url.link'

import SendIcon from '@mui/icons-material/Send';

export default function AdEvent({metadata}) {
  // console.log(metadata)
  const [valores, setValores] = useState({
    'fecha_inicio': '',
    'coordenada': '',
    'area':'',
    'concentracion':'',
    'cierre_evento': '',
    'tipo_evento': '',
    'punto_fuga': '',
    'linea': '',
    'deteccion': ''
  })

  const [validateForm, setvalidateForm] = useState(false)
  // console.log(metadata)
  // console.log(url)
  
  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/saveevento',{
      method: 'PUT',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {valores,metadata},

      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  const bot = async () => {
    const listaCuenta = await fetch('https://fugamoniaco.herokuapp.com/fugamoniaco',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "TIPO":"fuga",
        "COORDENADA":"urrutia",
        "AREA":"urrutia",
        "CONCENTRACION":55,
        "FECHA_EVENTO":"urrutia",
        "FECHA_CIERRE":"urrutia",
        "OPERADOR":"urrutia",
        "LINEA":"urrutia",
        "PUNTO_FUGA":"urrutia"
      })
    })
    let respons = await listaCuenta.json()
  }
  let getDatatime_Inicio = async () => {
    let fecha = new Date()
    let dd = fecha.getDate()<10?'0'+fecha.getDate():fecha.getDate()
    let mes = (fecha.getMonth()+1)<10?'0'+(fecha.getMonth()+1):(fecha.getMonth()+1)
    let yyyy = fecha.getFullYear()
    let hrs = fecha.getHours()<10?'0'+fecha.getHours():fecha.getHours()
    let min = fecha.getMinutes()<10?'0'+fecha.getMinutes():fecha.getMinutes()
    let isToTime = fecha.toString().split(' ')[4]
    fecha = fecha.toISOString()
    let isfecha = yyyy+'-'+mes+'-'+dd+'T'+hrs+':'+min
    // console.log(isfecha)
    setValores(
      {...valores, ['fecha_inicio']: isfecha, ['cierre_evento']: isfecha}
    )
  }

  let mutadorDataCoordenada = async (e) => {
    let txt = e.target.value
    // if(txt.length>1)setValores({...valores,[e.target.name]:txt[0]+'-'+txt.slice[0]})
    // else setValores({...valores,[e.target.name]:txt})
    setValores({...valores,[e.target.name]:txt.toUpperCase()})
  }

  let mutadorConcentracion = async (e) => {
    //console.log(e.target.name, e.target.value)
    if(e.target.value < 1) alert('El valor de la concentracion no puede ser menor a 1')
    else {
      setvalidateForm(true)
      setValores({...valores, [e.target.name]: e.target.value})
    }
  }

  const HandleSetValores = (e) => {
    console.log(e.target.name, e.target.value)
    setValores({
      ...valores, [e.target.name]: e.target.value.toUpperCase()
    })
  }
  const HandleSetValoresSelect = (e) => {
    console.log(e.target.name, e.target.value)
    setValores({
      ...valores, [e.target.name]: e.target.value
    })
  }
  
  useEffect(() => {
    getDatatime_Inicio()
    // fetchulta()
  }, [])
  
  const HandleSendValues = async () => {
    let isPssed = true
    let list = Object.keys(valores)
    list.forEach(elmt => {
      if(valores[elmt] === '')isPssed = false
    });
    if(isPssed && validateForm)fetchulta()
    else alert('Debe Completar todos los Campos.')
    return
  }

  return (
    <>
      <div className='container my-3'>
      <div className='row'>
        <div className='col-12 fondo-div py-3'>
          <h1>Ingreso Evento</h1>
        </div>
      </div>
    </div>
      <div className='container fondo-div py-4'>
        <div className='row'>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="datetime-local"
                name='fecha_inicio'
                fullWidth label="Fecha Evento"
                min='today'
                value={valores.fecha_inicio}
                onChange={(e)=>HandleSetValores(e)}
                variant="outlined" size='small' />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="text"
                name='coordenada'
                fullWidth
                label="Coordenada Plano Planta"
                value={valores.coordenada}
                onChange={mutadorDataCoordenada}
                variant="outlined"
                placeholder='Ej: H - 11'
                size='small' />
            </div>
            <div className='col-12 col-md-6 my-2'>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Area"
                  name="area"
                  value={valores.area}
                  onChange={HandleSetValoresSelect}
                  size='small'
                  fullWidth
                >
                  <MenuItem value='CMRA 201 PONIENTE'>CMRA 201 PONIENTE</MenuItem>
                  <MenuItem value='CMRA 201 ORIENTE'>CMRA 201 ORIENTE</MenuItem>
                  <MenuItem value='CMRA 207'>CMRA 207</MenuItem>
                  <MenuItem value='CMRA 202'>CMRA 202</MenuItem>
                  <MenuItem value='CMRA 304 7B'>CMRA 304 7B</MenuItem>
                  <MenuItem value='CMRA 304 7A'>CMRA 304 7A</MenuItem>
                  <MenuItem value='CMRA 304 6A'>CMRA 304 6A</MenuItem>
                  <MenuItem value='CMRA 304 6B'>CMRA 304 6B</MenuItem>
                  <MenuItem value='CMRA 304 5B'>CMRA 304 5B</MenuItem>
                  <MenuItem value='CMRA 304 5A'>CMRA 304 5A</MenuItem>
                  <MenuItem value='CMRA 208 A'>CMRA 208 A</MenuItem>
                  <MenuItem value='CMRA 208 B'>CMRA 208 B</MenuItem>
                  <MenuItem value='CMRA 208 C'>CMRA 208 C</MenuItem>
                  <MenuItem value='CMRA 208 D'>CMRA 208 D</MenuItem>
                  <MenuItem value='CMRA 290'>CMRA 290</MenuItem>
                  <MenuItem value='TÚNEL 1'>TÚNEL 1</MenuItem>
                  <MenuItem value='TÚNEL 23'>TÚNEL 23</MenuItem>
                  <MenuItem value='TÚNEL 4'>TÚNEL 4</MenuItem>
                  <MenuItem value='TÚNEL 56'>TÚNEL 56</MenuItem>
                  <MenuItem value='TÚNEL 78'>TÚNEL 78</MenuItem>
                  <MenuItem value='TÚNEL 9'>TÚNEL 9</MenuItem>
                  <MenuItem value='TÚNEL 10'>TÚNEL 10</MenuItem>
                  <MenuItem value='TÚNEL 11'>TÚNEL 11</MenuItem>
                  <MenuItem value='TÚNEL 12'>TÚNEL 12</MenuItem>
                  <MenuItem value='CONGELADOR ITALIANO'>CONGELADOR ITALIANO</MenuItem>
                  <MenuItem value='CONGELADOR DSI NORTE'>CONGELADOR DSI NORTE</MenuItem>
                  <MenuItem value='CONGELADOR DSI SUR'>CONGELADOR DSI SUR</MenuItem>
                  <MenuItem value='CONGELADOR TUCAL'>CONGELADOR TUCAL</MenuItem>
                  <MenuItem value='INTERCAMBIADOR VOTATOR'>INTERCAMBIADOR VOTATOR</MenuItem>
                  <MenuItem value='CONGELADOR IQF 1'>CONGELADOR IQF 1</MenuItem>
                  <MenuItem value='CONGELADOR IQF 4'>CONGELADOR IQF 4</MenuItem>
                  <MenuItem value='COMPRESOR 1028'>COMPRESOR 1028</MenuItem>
                  <MenuItem value='COMPRESOR 1104'>COMPRESOR 1104</MenuItem>
                  <MenuItem value='COMPRESOR 1030'>COMPRESOR 1030</MenuItem>
                  <MenuItem value='COMPRESOR 1031'>COMPRESOR 1031</MenuItem>
                  <MenuItem value='COMPRESOR 1057'>COMPRESOR 1057</MenuItem>
                  <MenuItem value='COMPRESOR 1060'>COMPRESOR 1060</MenuItem>
                  <MenuItem value='COMPRESOR 1063'>COMPRESOR 1063</MenuItem>
                  <MenuItem value='COMPRESOR 1069'>COMPRESOR 1069</MenuItem>
                  <MenuItem value='COMPRESOR 1072'>COMPRESOR 1072</MenuItem>
                  <MenuItem value='COMPRESOR 1106'>COMPRESOR 1106</MenuItem>
                  <MenuItem value='COMPRESOR 1124'>COMPRESOR 1124</MenuItem>
                  <MenuItem value='LÍNEA GENERAL LÍQUIDO'>LÍNEA GENERAL LÍQUIDO</MenuItem>
                  <MenuItem value='LÍNEA GENERAL DESCARGA'>LÍNEA GENERAL DESCARGA</MenuItem>
                  <MenuItem value='SCANBRINE'>SCANBRINE</MenuItem>
                  <MenuItem value='OSMOSIS'>OSMOSIS</MenuItem>
                  <MenuItem value='SALMUERA'>SALMUERA</MenuItem>
                  <MenuItem value='INTERCAMBIADOR GLICOL N°1'>INTERCAMBIADOR GLICOL N°1</MenuItem>
                  <MenuItem value='INTERCAMBIADOR GLICOL N°2'>INTERCAMBIADOR GLICOL N°2</MenuItem>
                  <MenuItem value='INTERCAMBIADOR GLICOL N°3'>INTERCAMBIADOR GLICOL N°3</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°1'>CHILLER DE AIRE CONJUNTO N°1</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°2'>CHILLER DE AIRE CONJUNTO N°2</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°3'>CHILLER DE AIRE CONJUNTO N°3</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°4'>CHILLER DE AIRE CONJUNTO N°4</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°5'>CHILLER DE AIRE CONJUNTO N°5</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°6'>CHILLER DE AIRE CONJUNTO N°6</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°7'>CHILLER DE AIRE CONJUNTO N°7</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°8'>CHILLER DE AIRE CONJUNTO N°8</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°9'>CHILLER DE AIRE CONJUNTO N°9</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°10'>CHILLER DE AIRE CONJUNTO N°10</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°11'>CHILLER DE AIRE CONJUNTO N°11</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°12'>CHILLER DE AIRE CONJUNTO N°12</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°13'>CHILLER DE AIRE CONJUNTO N°13</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°14'>CHILLER DE AIRE CONJUNTO N°14</MenuItem>
                  <MenuItem value='CHILLER DE AIRE CONJUNTO N°15'>CHILLER DE AIRE CONJUNTO N°15</MenuItem>
                  <MenuItem value='MADEF 1'>MADEF 1</MenuItem>
                  <MenuItem value='MADEF 2'>MADEF 2</MenuItem>
                  <MenuItem value='MADEF 3'>MADEF 3</MenuItem>
                  <MenuItem value='MADEF 4'>MADEF 4</MenuItem>
                  <MenuItem value='BANCO DE AGUA A'>BANCO DE AGUA A</MenuItem>
                  <MenuItem value='BANCO DE AGUA B'>BANCO DE AGUA B</MenuItem>
                  <MenuItem value='BANCO DE AGUA C'>BANCO DE AGUA C</MenuItem>
                  <MenuItem value='RECIRCULADOR ALTA N°1'>RECIRCULADOR ALTA N°1</MenuItem>
                  <MenuItem value='RECIRCULADOR ALTA N°2'>RECIRCULADOR ALTA N°2</MenuItem>
                  <MenuItem value='RECIRCULADOR BAJA N°1'>RECIRCULADOR BAJA N°1</MenuItem>
                  <MenuItem value='RECIRCULADOR BAJA N°2'>RECIRCULADOR BAJA N°2</MenuItem>
                  <MenuItem value='RECIRCULADOR BAJA N°3'>RECIRCULADOR BAJA N°3</MenuItem>
                  <MenuItem value='RECIRCULADOR IQF'>RECIRCULADOR IQF</MenuItem>
                  <MenuItem value='TRAMPA DE SUCCIÓN'>TRAMPA DE SUCCIÓN</MenuItem>
                  <MenuItem value='RECIBIDOR DE LÍQUIDO PRIMARIO'>RECIBIDOR DE LÍQUIDO PRIMARIO</MenuItem>
                  <MenuItem value='RECIBIDOR DE LÍQUIDO SECUNDARIO'>RECIBIDOR DE LÍQUIDO SECUNDARIO</MenuItem>
                </TextField>
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="number"
                name='concentracion'
                fullWidth
                label="Concentracion (ppm)"
                value={valores.concentracion}
                onChange={mutadorConcentracion}
                variant="outlined"
                placeholder='Valor en ppm'
                size='small' />
            </div>
            <div className='col-12 col-md-6 my-2 text-center'>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="tipo_evento" onChange={HandleSetValores}>
                  <FormControlLabel value="fuga" control={<Radio />} label="Fuga" data-toggle="tooltip" data-placement="top" title="Tooltip on top" />
                  <FormControlLabel value="filtracion" control={<Radio />} label="Filtracion" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='col-12 col-md-6 my-2'>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Linea"
                  name="linea"
                  value={valores.linea}
                  onChange={HandleSetValoresSelect}
                  size='small'
                  fullWidth
                >
                  <MenuItem value='SUCCION'>Succion</MenuItem>
                  <MenuItem value='LIQUIDO'>Liquido</MenuItem>
                  <MenuItem value='GAS CALIENTE'>Gas Caliente</MenuItem>
                </TextField>
            </div>
            <div className='col-12 col-md-6 my-2'>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Punto Fuga"
                  name="punto_fuga"
                  value={valores.punto_fuga}
                  onChange={HandleSetValoresSelect}
                  size='small'
                  fullWidth
                >
                  <MenuItem value='FISURA EN LINEA'>FISURA EN LINEA</MenuItem>
                  <MenuItem value='VALVULA O MANOMETRO'>VALVULA O MANOMETRO</MenuItem>
                  <MenuItem value='FLEXIBLE O MANGUERA HIDRAULICA'>FLEXIBLE O MANGUERA HIDRAULICA</MenuItem>
                  <MenuItem value='JUNTA, FITTING, FLANGE'>JUNTA, FITTING, FLANGE</MenuItem>
                  <MenuItem value='SELLO COMPRESOR'>SELLO COMPRESOR</MenuItem>
                </TextField>
            </div>
            <div className='col-12 col-md-6 my-2'>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Metodo De Deteccion"
                  name="deteccion"
                  value={valores.deteccion}
                  onChange={HandleSetValoresSelect}
                  size='small'
                  fullWidth
                >
                  <MenuItem value='DETECCION CON AZUFRE'>DETECCION CON AZUFRE</MenuItem>
                  <MenuItem value='DETECTOR PORTÁTIL'>DETECTOR PORTÁTIL</MenuItem>
                  <MenuItem value='SENSORES NH3'>SENSORES NH3</MenuItem>
                  <MenuItem value='OLOR EN AMBIENTE'>OLOR EN AMBIENTE</MenuItem>
                  <MenuItem value='AVISO EXTERNO'>AVISO EXTERNO</MenuItem>
                </TextField>
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="datetime-local"
                name='cierre_evento'
                fullWidth
                label="Cierre Evento"
                value={valores.cierre_evento}
                onChange={HandleSetValores}
                variant="outlined"
                size='small' />
            </div>
            <div className='col-12 my-2 text-center'>
              <Button variant='contained' size='small' endIcon={<SendIcon />} onClick={()=>HandleSendValues()}>Guardar Evento</Button>
            </div>
        </div>
      </div>
    </>
    
  )
}