import React, { useEffect, useState } from 'react'
import { ChartLine } from '../chart/ChartLine'
import { GetDataChart } from '../helper/GetDataChart.api';

export const ChartOrdenCompra = ({metadata, selectEmp}) => {
  
  const [datos, setDatos] = useState([]);

  const getDataChart_ = async () => {
    const data = await GetDataChart({metadata, selectEmp})
    // console.log(data)
    setDatos(data)
  }
  useEffect(() => {
    getDataChart_();
  }, [selectEmp])
  
  return (
    <>
      <ChartLine data={datos} titleChart={'Historial Compra'} />
    </>
  )
}
