import React, { useEffect, useReducer, useState } from "react";
import {BrowserRouter, Link, NavLink, Route, Routes} from 'react-router-dom'

import './AppStyle.css'

import Login from "./views/sesion/Login";
import ForgetPass from "./views/sesion/ForgetPass";
import IndexView from "./views/tempore/Index"
import IndexView2 from "./views/tempore/index2"
import SessionClose from './views/sesion/CloseSion'
import Header from "./views/sesion/Header";
import AddData from "./views/user/AddData.view"
import DashBoard from "./views/user/DashboardData.view";
import AddEvent from "./views/user/AdEvent.view"
/** rutas admin- */
import DashBoardAdmin from './views/admin/DashBoard.view'
import Graficar from './views/admin/Graficar.view'
import CuentasAdmin from './views/admin/DashBoarCuentas.view'
/** rutas suoperUser */
import CuentaSu from './views/superUser/CuentasSu.view'
import DashBoarSu from './views/superUser/DashBoarSu.view'
import GraficarSu from './views/superUser/GraficarSu.view'
/** created by MarcuxoDevOps */
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DashBoarDatos from "./views/admin/DashBoarDatos.view";
import AppendEvent from "./views/user/AppendEvent.view";
import VerDatosPlanta from "./views/superUser/VerDatosPlanta.view";
import DownloadFilen from "./views/extApi/DownloadFilen.api";
import { DashBoardAmoniaco } from "./views/admin/DashBoardAmoniaco.view";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

export const AuthContext = React.createContext()

const initialState = {
  isAutenticated : false,
  user: null,
  token: null,
  rut: null,
  tuser: null,
  empresa: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      sessionStorage.setItem('token',action.payload.token)
      sessionStorage.setItem('user',action.payload.user)
      sessionStorage.setItem('rut',action.payload.rut)
      sessionStorage.setItem('tuser',action.payload.tuser)
      sessionStorage.setItem('empresa',action.payload.empresa)
      return {
        ...state,
        isAutenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        rut: action.payload.rut,
        tuser: action.payload.tuser,
        empresa: action.payload.empresa
      }
    case 'LOGOUT':
      sessionStorage.clear()
      return {
        ...state,
        isAutenticated: null,
        user: null,
        token: null,
        rut: null,
        tuser: null,
        empresa: null
      }
  
    default:
      return state
  }
}
function App() {
  const [menu, setMenu] = useState({right: false});
  const [state, dispatch] = useReducer(reducer, initialState);

  let activeStyle = {
    backgroundColor: 'white',
  };

  useEffect(() => {
    //console.log('recarga de aplciacion o paginas')
    const token = sessionStorage.getItem('token') || null
    const user = sessionStorage.getItem('user') || null
    const rut = sessionStorage.getItem('rut') || null
    const tuser = sessionStorage.getItem('tuser') || null
    const empresa = sessionStorage.getItem('empresa') || null
    if(user && token){
      dispatch({
        type: 'LOGIN',
        payload: {
          token,
          user,
          rut,
          tuser,
          empresa
        }
      })
    }
  }, [])

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMenu({ ...menu, [anchor]: open });
  };

  if(!state.token){
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" index element={<Login />} />
            <Route path="/forgetpass" element={<ForgetPass />} />
            <Route path='*' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    )
  }

  if(state.tuser === "superUser" || state.tuser === "megaUser"){
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <BrowserRouter>
          {/* {console.log('state',state)} */}
          <SwipeableDrawer
            anchor='right'
            open={menu['right']}
            onClose={toggleDrawer('right',false)}
            onOpen={toggleDrawer('right', true)}
          >
            <Box
              role="presentation"
              onClick={toggleDrawer('right',false)}
              onKeyDown={toggleDrawer('right',false)}
              className="header-bg"
            >
              <List>
                <ListItem>
                  <div className="text-center">
                    <img src="/img/logo.svg" width="80%"/>
                  </div>
                </ListItem>
                <Divider />
                <Divider />
                <Divider />

                  <Link to='/datos'>
                    <ListItem>
                      <ListItemIcon><DriveFileRenameOutlineOutlinedIcon /></ListItemIcon>
                      <ListItemText className='text-white'> Dashboard Datos</ListItemText>
                    </ListItem>
                  </Link>

                  <Link to='/graficar'>
                    <ListItem>
                      <ListItemIcon><InsertChartOutlinedTwoToneIcon /></ListItemIcon>
                      <ListItemText className='text-white'> Graficar</ListItemText>
                    </ListItem>
                  </Link>

                  <Link to='/dashboard'>
                    <ListItem>
                      <ListItemIcon><DashboardOutlinedIcon /></ListItemIcon>
                      <ListItemText className='text-white'> Dashboard Items</ListItemText>
                    </ListItem>
                  </Link>

                  <Link to='/cuentas'>
                    <ListItem>
                      <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
                      <ListItemText className='text-white'> Dashboard Cuentas</ListItemText>
                    </ListItem>
                  </Link>

                  <Link to='/ctrlamoniaco'>
                    <ListItem>
                      <ListItemIcon><ReceiptLongOutlinedIcon /></ListItemIcon>
                      <ListItemText className='text-white'> Control Amoniaco</ListItemText>
                    </ListItem>
                  </Link>
                
                  <DownloadFilen metadata={state} />

                  <Link to='/salir'>
                    <ListItem>
                      <ListItemIcon><LogoutIcon /></ListItemIcon>
                      <ListItemText className='text-white'>Salir</ListItemText>
                    </ListItem>
                  </Link>

              </List>
            </Box>
          </SwipeableDrawer>
          
          <Header metadata={state} toggleDrawer={toggleDrawer} />
          <Routes>
            <Route path="/" element={<VerDatosPlanta metadata={state} />} />
            <Route path="/graficar" element={<Graficar metadata={state} />} />
            {/* <Route path="/graficar" element={<GraficarSu metadata={state} />} /> */}
            <Route path="/dashboard" element={<DashBoarSu metadata={state} />} />
            <Route path="/datos" element={<VerDatosPlanta metadata={state} />} />
            <Route path="/sortable" element={<VerDatosPlanta metadata={state} />} />
            <Route path="/cuentas" element={<CuentaSu metadata={state} />} />
            <Route path="/ctrlamoniaco" element={<DashBoardAmoniaco metadata={state} />} />
            <Route path="/salir" element={<SessionClose />} />
            <Route path='/*' element={<VerDatosPlanta metadata={state} />} />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    )
  }
  if(state.tuser === "user"){
    return (
      <AuthContext.Provider
      value={{state, dispatch}}
    >
      <BrowserRouter>
        {/* {console.log('state',state)} */}
        <SwipeableDrawer
          anchor='right'
          open={menu['right']}
          onClose={toggleDrawer('right',false)}
          onOpen={toggleDrawer('right', true)}
        >
          <Box
            role="presentation"
            onClick={toggleDrawer('right',false)}
            onKeyDown={toggleDrawer('right',false)}
            className="header-bg"
          >
            <List>
              <ListItem>
                <div className="text-center">
                  <img src="/img/logo.svg" width="80%"/>
                </div>
              </ListItem>
              <Divider />
              <Divider />
              <Divider />
              {/* <Link to='/'>
                <ListItem>
                  <ListItemIcon><CottageIcon /></ListItemIcon>
                  <ListItemText className='text-white'> Inicio</ListItemText>
                </ListItem>
              </Link> */}

              {/* <Link to='/pagina'>
                <ListItem>
                  <ListItemIcon><AccountTreeOutlinedIcon /></ListItemIcon>
                  <ListItemText className='text-white'> Ejemplo de ruta en menu</ListItemText>
                </ListItem>
              </Link> */}

              {/* <Link to='/ingreso'>
                <ListItem>
                  <ListItemIcon><EditNotificationsIcon /></ListItemIcon>
                  <ListItemText className='text-white'> Ingreso Evento Uno</ListItemText>
                </ListItem>
              </Link> */}

              <NavLink to='/evento'>
                <ListItem>
                  <ListItemIcon><EditNotificationsIcon /></ListItemIcon>
                  <ListItemText className='text-white'> Ingreso Evento</ListItemText>
                </ListItem>
              </NavLink>

              <DownloadFilen metadata={state} />

              {/* <Link to='/dashboard'>
                <ListItem>
                  <ListItemIcon><DashboardIcon /></ListItemIcon>
                  <ListItemText className='text-white'> Dashboard De Datos</ListItemText>
                </ListItem>
              </Link> */}
              <NavLink to='/salir'>
                <ListItem>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText className='text-white'>Salir</ListItemText>
                </ListItem>
              </NavLink>
            </List>
          </Box>
        </SwipeableDrawer>
        
        <Header metadata={state} toggleDrawer={toggleDrawer} />
        <Routes>
          <Route path="/evento" element={<AppendEvent metadata={state} />} />
          <Route path="/" element={<IndexView metadata={state} />} />
          <Route path="/pagina" element={<IndexView2 metadata={state} />} />
          <Route path="/ingreso" element={<AddData metadata={state} />} />
          <Route path="/dashboard" element={<DashBoard metadata={state} />} />
          <Route path="/salir" element={<SessionClose />} />
        </Routes>
      </BrowserRouter>
      </AuthContext.Provider>
    )
  }
  if(state.tuser === "admin"){
    return (
      <AuthContext.Provider
      value={{state, dispatch}}
    >
      <BrowserRouter>
        {/* {console.log('state',state)} */}
        <SwipeableDrawer
          anchor='right'
          open={menu['right']}
          onClose={toggleDrawer('right',false)}
          onOpen={toggleDrawer('right', true)}
        >
          <Box
            role="presentation"
            onClick={toggleDrawer('right',false)}
            onKeyDown={toggleDrawer('right',false)}
            className="header-bg"
          >
            <List>
              <ListItem>
                <div className="text-center">
                  <img src="/img/logo.svg" width="80%"/>
                </div>
              </ListItem>
              <Divider />
              <Divider />
              <Divider />

                <Link to='/dashboadatos'>
                  <ListItem>
                    <ListItemIcon><DriveFileRenameOutlineOutlinedIcon /></ListItemIcon>
                    <ListItemText className='text-white'> Dashboard Datos</ListItemText>
                  </ListItem>
                </Link>

                <Link to='/graficar'>
                  <ListItem>
                    <ListItemIcon><InsertChartOutlinedTwoToneIcon /></ListItemIcon>
                    <ListItemText className='text-white'> Graficar</ListItemText>
                  </ListItem>
                </Link>

                <Link to='/dashboard'>
                  <ListItem>
                    <ListItemIcon><DashboardOutlinedIcon /></ListItemIcon>
                    <ListItemText className='text-white'> Dashboard Items</ListItemText>
                  </ListItem>
                </Link>

                <Link to='/ctrlamoniaco'>
                  <ListItem>
                    <ListItemIcon><ReceiptLongOutlinedIcon /></ListItemIcon>
                    <ListItemText className='text-white'> Control Amoniaco</ListItemText>
                  </ListItem>
                </Link>

                {/* <Link to='/cuentas'>
                  <ListItem>
                    <ListItemIcon><ManageAccountsOutlinedIcon /></ListItemIcon>
                    <ListItemText className='text-white'> Dashboard Cuentas</ListItemText>
                  </ListItem>
                </Link> */}

                <DownloadFilen metadata={state}/>
              
                <Link to='/salir'>
                  <ListItem>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText className='text-white'>Salir</ListItemText>
                  </ListItem>
                </Link>

            </List>
          </Box>
        </SwipeableDrawer>
        <Header metadata={state} toggleDrawer={toggleDrawer} />
        <Routes>
          <Route path="/" element={<DashBoarDatos metadata={state} />} />
          <Route element={<IndexView metadata={state} />} />
          <Route path="/cuentas" element={<CuentasAdmin metadata={state} />} />
          <Route path="/graficar" element={<Graficar metadata={state} />} />
          <Route path="/evento" element={<AddEvent metadata={state} />} />
          <Route path="/dashboadatos" element={<DashBoarDatos metadata={state} />} />
          <Route path="/dashboard" element={<DashBoardAdmin metadata={state} />} />
          <Route path="/ctrlamoniaco" element={<DashBoardAmoniaco metadata={state} />} />
          <Route path="/salir" element={<SessionClose />} />
          <Route path="*" element={<DashBoarDatos metadata={state} />} />
        </Routes>
        
      </BrowserRouter>
      </AuthContext.Provider>
    )
  }
}

export default App;
