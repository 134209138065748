import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { url } from '../../../varEntorn/Url.link';

export default function SelectPlanta({metadata,setSelectPlantaValue}) {
  const [SelectValue, setSelectValue] = useState({
    'empresa': metadata.empresa
  });
  const [planta, setPlanta] = useState([]);
  const HandleSetPlantaSelect = async (e) => {
    setSelectValue({
      ...SelectValue, [e.target.name]: e.target.value
    })
    setSelectPlantaValue(e.target.value)
  }

  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getplantas',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: 'parametros',

      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      // console.log(respons.data)
      await setPlanta(respons.data.body)
      // window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  useEffect(() => {
    fetchulta()
  }, [])

  return(
    <>
      <TextField
        id="outlined-select-currency"
        select
        label="Planta"
        value={SelectValue.empresa}
        onChange={(e)=>HandleSetPlantaSelect(e)}
        name='empresa'
        fullWidth
        size='small'
      >
        {planta.map((index, itm) => 
          <MenuItem key={itm} value={index.NOMBRE}>{index.NOMBRE}</MenuItem>
        )}
      </TextField>
    </>
  );
}
