import { url } from "../../../../varEntorn/Url.link";

export const GetPlantasArr = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(url+'/fugatorio/getempresas',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

        const reqkkst = await query.json();
        // console.log(reqkkst)
        if(reqkkst.success){
          resolve(reqkkst.body)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    

  })
}
