import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';

export default function Header({metadata, toggleDrawer}) {
  
  return (
    <>
      <div className='container-fluid sombra header-bg'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center text-right py-2'>
            <img src='./img/logo.svg' height='87px' className='px-2 hidden-xs' />
          </div>
            <label>
              <p className='text-warning p-0 m-0'>Usuario: {metadata.user}</p>
              <p className='text-warning p-0 m-0'>Empresa: {metadata.empresa}</p>
              <span className="cursor-menu" onClick={toggleDrawer('right', true)}><MenuIcon fontSize="large" /></span>
            </label>
        </div>
      </div>
    </>
  )
}
