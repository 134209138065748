import React, { useEffect, useState } from 'react';
import {url} from '.././../../varEntorn/Url.link'
import ApexChart from 'react-apexcharts'

export default function Grafico({metadata, valor, empresa}) {
  console.log('*-*-',empresa)
  const [rptInic, setRptInic] = useState({
    "diario":'',
    "mensual":''
  });
  const [Categoria, setCategoria] = useState([]);
  const [Serie, setSerie] = useState([]);

  const HandleQueryToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/getthechart',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: valor,
        empresa: empresa,
        user: metadata.tuser
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
      * del session storage y se redirige al inicio
      */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      // console.log(respons.data)
        if(respons.data.body === 'reporte') return
        setCategoria(respons.data.body[0])
        setSerie(respons.data.body[1])
    }
  }

  useEffect(() => {
    HandleQueryToServer()
  }, [valor, empresa]);
  
  let state = {
          
    series: [{
      name: 'Eventos',
      data: Serie
    }],
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          top: 0,
          left: 3,
          blur: 2,
          opacity: 0.2
        },
        height: 350,
        type: 'bar',
        events: {
          click: function(event, chartContext, config) {
            if(config.config.xaxis.categories[config.dataPointIndex])console.log('diste Click a:'+ config.config.xaxis.categories[config.dataPointIndex])
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,//horientacion de las columnas
          columnWidth: '50%',//ancho de la columna
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
          style: {
            fontSize: '12px',// tamaño de fuente para las etiquetas sobre cada barra
            colors: ["#304758"]// color del label
          }
      },
      legend: {
        show: false
      },
      noData: {
        text: 'sin Datos para Graficar 📊',
        style: {
          color: '',
          fontSize: '25px',
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.5,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 0]
        },
      },
      xaxis: {
        categories: Categoria,
        labels: {
          style: {
            fontSize: '10px'//tamaño de fuente de etiquetas infetiores
          }
        }
      }
    },
  
  
  };
  
  return (
    <>
      <div>
        {/* <AddchartOutlinedIcon color='secondary' />
        <InsertChartOutlinedIcon color='secondary' /> */}
        <div className='text-center'>
          
        </div>
      </div>

      <div className='container-fluid fondo-div'>
        <div className='row'>
          <div className='col text-center'>
            <h5 className='t-shadow'>{valor==='FSHORT'?'FECHA':valor} - {empresa}</h5>
            <ApexChart options={state.options || null} series={state.series || null} type="bar" height={350} />
          </div>
        </div>
      </div>
    </>
  );
}

// /** reporte */
// if(respons.data.body.diario.length !== 0){
//   //console.log('Diario',);
//   setRptInic({
//     ...rptInic, "diario": `Filtracion: ${respons.data.body.diario[0].total}, Fuga: ${respons.data.body.diario[1].total}`
//   })
// }
// if(respons.data.body.mensual.length !== 0){
//   //console.log('Mensual',respons.data.body.mensual);
//   setRptInic({
//     ...rptInic, "mensual": `Filtracion: ${respons.data.body.mensual[0].total}, Fuga: ${respons.data.body.mensual[1].total}`
//   })
// }
//<span>Se han detectado este mes {rptInic.mensual===''? '0 eventos':rptInic.mensual} y el dia de hoy se detectaron {rptInic.diario===''? '0 eventos':rptInic.diario}</span>