import React, { useState } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Drawer, IconButton, MenuItem, TextField } from '@mui/material';
import { url } from '../../../../../varEntorn/Url.link';

export default function EdithPlantata({metadata, campo, data, setIsReload}) {
  //console.log(data)
  const [state, setState] = useState({bottom: false});
  const [nameData, setnameData] = useState({
    "NOMBRE": data.NOMBRE,
    "PLANTA": data.PLANTA,
    "STATE": data.STATE
  })
  const [isLoading, setisLoading] = useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleSetData = async (e) => {
    //console.log(e.target.name, e.target.value)
    setnameData({...nameData, [e.target.name]: e.target.value})
  }

  const HandleSendDatoToServer = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/edithitemad',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO', 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: nameData,
        campo: campo,
        id: data._id
      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      //window.location.reload()
      //setDataList(respons.data.body)
      setState({bottom: false})
      setIsReload(true)
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  return (
    <>
      <IconButton aria-label="edit" color='info' size='small' onClick={toggleDrawer('bottom', true)}>
        <SettingsOutlinedIcon />
      </IconButton>
      <Drawer
        anchor='bottom'
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        <div className="container table-responsive pb-5">
          <div className="row">
            <div className="col-12 py-3"><h5>Editar</h5></div>

            <div className="col-12 col-lg-7 py-2">
              <TextField fullWidth
                id="outlined-basic"
                name="NOMBRE"
                label="Nombre"
                size="small"
                variant="outlined"
                value={nameData.NOMBRE}
                onChange={(e)=>HandleSetData(e)}
              />
            </div>
            <div className="col-12 col-lg-5 py-2">
              <TextField
                id="outlined-select-currency"
                select
                label="Estado"
                // value={currency}
                // onChange={handleChange}
                fullWidth
                size='small'
                name='STATE'
                value={nameData.STATE}
                onChange={(e)=>HandleSetData(e)}
              >
                {/* <MenuItem key={option.value} value={option.value}>planta 1</MenuItem> */}
                <MenuItem value={true}>activo</MenuItem>
                <MenuItem value={false}>inactivo</MenuItem>
              </TextField>
            </div>
            <div className="col-12 py-2"> 
              <button className="btn btn-info " onClick={()=>HandleSendDatoToServer()}>
                {
                  isLoading?"Cargando":"Editar"
                }
                </button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}
