import { Button, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'


export default function Medidor() {
  const [IsHidden, setIsHidden] = useState(false)
  const [DataForm, setDataForm] = useState({
    'nombre': '',
    'tipo': '',
    'options': ''
  })

  const HandleSetSelect = async (e) => {
    // setDataForm(
    //   {...DataForm, [e.target.name]: e.target.value}
    // )
    if(e.target.value === 'select'){
      // console.log(e.target.name, e.target.value)
      setDataForm(
        {...DataForm, [e.target.name]: e.target.value}
      )
      setIsHidden(true)
    }else{
      setDataForm(
        {...DataForm, [e.target.name]: e.target.value, "options": ""}
      )
      setIsHidden(false)
    }
  }

  const HandleSetDataForm = async (e) => {
    // console.log(e.target.name, e.target.value)
    setDataForm(
      {...DataForm, [e.target.name]: e.target.value}
    )
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-4 my-2'>
            <TextField
              onChange={(e)=>HandleSetDataForm(e)}
              value={DataForm.nombre}
              name='nombre'
              fullWidth
              size='small'
              label="Nombre"
              variant="outlined"
            />
          </div>
          <div className='col-12 col-lg-4 my-2'>
            <TextField
              select
              label="Tipo Dato"
              value={DataForm.tipo}
              onChange={(e)=>HandleSetSelect(e)}
              name='tipo'
              fullWidth
              size='small'
            >
                <MenuItem value={'text'}>Texto</MenuItem>
                <MenuItem value={'number'}>Numero</MenuItem>
                <MenuItem value={'select'}>Seleccion</MenuItem>
            </TextField>
          </div>
          <div className='col-12 col-lg-4 my-2'>
          {
            IsHidden?
            <TextField
              onChange={(e)=>HandleSetDataForm(e)}
              value={DataForm.options}
              name='options'
              fullWidth
              size='small'
              label="Opciones"
              variant="outlined"
            />
            :
            null
          }
          </div>
          <div className='col-12 col-lg-4 text-center my-2'>
            <Button variant='contained' size='small' onClick={()=>console.log(DataForm)}>Crear Medidor</Button>
          </div>
          <div className='col-12 col-lg-4 text-center my-2'>
            
          </div>
        </div>
      </div>
    </>
  )
}

