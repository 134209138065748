import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Tooltip, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { url } from '../../varEntorn/Url.link'
import SendIcon from '@mui/icons-material/Send';
import Area from './component/Area.select.component';
import Linea from './component/Linea.select.component';
import MethDetec from './component/MethDetec.select.component';
import PuntoFuga from './component/PuntoFuga.select.component';


export default function AppendEvent({metadata}) {
   // console.log(metadata)
   const [valores, setValores] = useState({
    'fecha_inicio': '',
    'coordenada': '',
    'area':'',
    'concentracion':'',
    'cierre_evento': '',
    'tipo_evento': '',
    'punto_fuga': '',
    'linea': '',
    'deteccion': ''
  })

  const [validateForm, setvalidateForm] = useState(false)
  // console.log(metadata)
  // console.log(url)
  
  const fetchulta = async () => {
    /**standar de consulta a endpont con fetch */
    const listaCuenta = await fetch(url+'/fugatorio/saveevento',{
      method: 'PUT',
      headers: {
        'authorization': metadata.token,
        'aplicacion': 'FUGAMONIACO',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {valores,metadata},

      })
    })
    let respons = await listaCuenta.json()

    /** en caso de obtener un respos false por parte del endpoin se borran las credenciales
     * del session storage y se redirige al inicio
     */
    if(!respons.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }else{
      window.location.reload()
    }
    /**standar de consulta a endpont con fetch */
    /** a continuacion ingrese la logica para una respons success por parte del endpoint */
  }

  const bot = async () => {
    const listaCuenta = await fetch('https://fugamoniaco.herokuapp.com/fugamoniaco',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "TIPO":"fuga",
        "COORDENADA":"urrutia",
        "AREA":"urrutia",
        "CONCENTRACION":55,
        "FECHA_EVENTO":"urrutia",
        "FECHA_CIERRE":"urrutia",
        "OPERADOR":"urrutia",
        "LINEA":"urrutia",
        "PUNTO_FUGA":"urrutia"
      })
    })
    let respons = await listaCuenta.json()
  }

  let getDatatime_Inicio = async () => {
    let fecha = new Date()
    let dd = fecha.getDate()<10?'0'+fecha.getDate():fecha.getDate()
    let mes = (fecha.getMonth()+1)<10?'0'+(fecha.getMonth()+1):(fecha.getMonth()+1)
    let yyyy = fecha.getFullYear()
    let hrs = fecha.getHours()<10?'0'+fecha.getHours():fecha.getHours()
    let min = fecha.getMinutes()<10?'0'+fecha.getMinutes():fecha.getMinutes()
    let isToTime = fecha.toString().split(' ')[4]
    fecha = fecha.toISOString()
    let isfecha = yyyy+'-'+mes+'-'+dd+'T'+hrs+':'+min
    // console.log(isfecha)
    setValores(
      {...valores, ['fecha_inicio']: isfecha, ['cierre_evento']: isfecha}
    )
  }

  let mutadorDataCoordenada = async (e) => {
    let txt = e.target.value
    // if(txt.length>1)setValores({...valores,[e.target.name]:txt[0]+'-'+txt.slice[0]})
    // else setValores({...valores,[e.target.name]:txt})
    setValores({...valores,[e.target.name]:txt.toUpperCase()})
  }

  let mutadorConcentracion = async (e) => {
    //console.log(e.target.name, e.target.value)
    if(e.target.value < 1) alert('El valor de la concentracion no puede ser menor a 1')
    else {
      setvalidateForm(true)
      setValores({...valores, [e.target.name]: e.target.value})
    }
  }

  const HandleSetValores = (e) => {
    // console.log(e.target.name, e.target.value)
    setValores({
      ...valores, [e.target.name]: e.target.value.toUpperCase()
    })
  }
  const HandleSetValoresSelect = (e) => {
    // console.log(e.target.name, e.target.value)
    setValores({
      ...valores, [e.target.name]: e.target.value
    })
  }
  
  useEffect(() => {
    getDatatime_Inicio()
    // fetchulta()
  }, [])
  
  const HandleSendValues = async () => {
    let isPssed = true
    let list = Object.keys(valores)
    list.forEach(elmt => {
      if(valores[elmt] === '')isPssed = false
    });
    if(isPssed && validateForm)fetchulta()
    else alert('Debe Completar todos los Campos.')
    return
  }

  return (
    <>
      <div className='container my-3'>
      <div className='row'>
        <div className='col-12 fondo-div py-3'>
          <h1>Ingreso Evento</h1>
        </div>
      </div>
    </div>
      <div className='container fondo-div py-4'>
        <div className='row'>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="datetime-local"
                name='fecha_inicio'
                fullWidth label="Fecha Evento"
                min='today'
                value={valores.fecha_inicio}
                onChange={(e)=>HandleSetValores(e)}
                variant="outlined" size='small' />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="text"
                name='coordenada'
                fullWidth
                label="Coordenada Plano Planta"
                value={valores.coordenada}
                onChange={mutadorDataCoordenada}
                variant="outlined"
                placeholder='Ej: H - 11'
                size='small' />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <Area
                metadata={metadata}
                HandleSetValoresSelect={HandleSetValoresSelect}
                valores={valores}
              />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="number"
                name='concentracion'
                fullWidth
                label="Concentracion (ppm)"
                value={valores.concentracion}
                onChange={mutadorConcentracion}
                variant="outlined"
                placeholder='Valor en ppm'
                size='small' />
            </div>
            <div className='col-12 col-md-6 my-2 text-center'>
              <Tooltip title="Si la concentracion es mayor a 25ppm, corresponde a una fuga" TransitionComponent={Zoom} placement={'top'}>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="gender" name="tipo_evento" onChange={HandleSetValores}>
                    <FormControlLabel value="fuga" control={<Radio />} label="Fuga" />
                    <FormControlLabel value="filtracion" control={<Radio />} label="Filtracion" />
                  </RadioGroup>
                </FormControl>
              </Tooltip>
            </div>
            <div className='col-12 col-md-6 my-2'>
              <Linea
                metadata={metadata}
                HandleSetValoresSelect={HandleSetValoresSelect}
                valores={valores}
              />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <PuntoFuga
                metadata={metadata}
                HandleSetValoresSelect={HandleSetValoresSelect}
                valores={valores}
              />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <MethDetec
                metadata={metadata}
                HandleSetValoresSelect={HandleSetValoresSelect}
                valores={valores}
              />
            </div>
            <div className='col-12 col-md-6 my-2'>
              <TextField
                id="outlined-basic"
                type="datetime-local"
                name='cierre_evento'
                fullWidth
                label="Cierre Evento"
                value={valores.cierre_evento}
                onChange={HandleSetValores}
                variant="outlined"
                size='small' />
            </div>
            <div className='col-12 my-2 text-center'>
              <Button variant='contained' size='small' endIcon={<SendIcon />} onClick={()=>HandleSendValues()}>Guardar Evento</Button>
            </div>
        </div>
      </div>
    </>
    
  )
}